import { put } from 'redux-saga/effects';
import axios from '../../../utils/axios';
import { BASEURL } from '../../../BaseUrl/BaseUrl';
import {
  startFormLoading,
  hasError,
  getErrorMessage,
  getSuccessMessage,
  setResponse,
  getDeleteMessage,
} from '../../slices/responseStatus';
import { isLogout } from '../../slices/login';
import { startLoading, getHolidaysDetils, deleteHolidays, getTimeForReminder, getDeleteNotificationStatus } from '../../slices/notificationSetting';

export function* addholidays(action) {
  try {
    yield put(startFormLoading());
    const { notificationTime, holidays } = action;
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.post(
      `${BASEURL}/notification`,
      { holidays, notificationTime },
      {
        headers: {
          'x-auth-token': `${Token}`,
        },
      }
    );
    yield put(getTimeForReminder(notificationTime));
    yield put(getSuccessMessage(response.data.meta.message));
    yield put(setResponse(response.status));
  } catch (error) {
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
    if (error.error?.statusCode === 401) {
      yield put(isLogout(true));
    }
  }
}

export function* holidaysSaga() {
  try {
    yield put(hasError(false));
    yield put(startLoading(true));
    yield put(startFormLoading());
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.get(`${BASEURL}/notification/holidays`, {
      headers: {
        'x-auth-token': Token,
      },
    });
    const holidaysDetils = response?.data?.data?.Holidays?.data || [];
    const { notificationTime } = response?.data?.data?.Holidays;
    yield put(getHolidaysDetils(holidaysDetils));
    yield put(getTimeForReminder(notificationTime));
  } catch (error) {
    yield put(startLoading(false));
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
    if (error.error?.statusCode === 401) {
      yield put(isLogout(true));
    }
  }
}
export function* deleteHolidaysSaga(state) {
  try {
    yield put(startLoading(true));
    yield put(hasError(false));
    const { id } = state;
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.delete(`${BASEURL}/notification/holidays/${id}`, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    if (response.status === 200) {
      yield put(deleteHolidays(id));
    }
    // yield call(employeeListSaga);
    yield put(startLoading(false));
    yield put(getDeleteNotificationStatus(response.status));
    yield put(getDeleteMessage(response.data.meta.message));
    yield put(startLoading(false));
  } catch (error) {
    yield put(startLoading(false));
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
  }
}
