import { takeLatest } from 'redux-saga/effects';
import sagaActions from '../../actions/index';
import { logOut, signinSaga, getProfileDetails, updateProfile, sendOtpForLogin } from './loginSaga';
import { signupSaga, signupEmailVerifySaga } from './registerSaga';

export function* loginSaga() {
  yield takeLatest(sagaActions.SEND_OTP_FOR_LOGIN, sendOtpForLogin);
  yield takeLatest(sagaActions.SIGNIN_SAGA, signinSaga);
  yield takeLatest(sagaActions.LOG_OUT, logOut);
  yield takeLatest(sagaActions.GET_PROFILE, getProfileDetails);
  yield takeLatest(sagaActions.UPDATE_PROFILE, updateProfile);
  yield takeLatest(sagaActions.SIGNUP_SAGA, signupSaga);
  yield takeLatest(sagaActions.GET_SIGNUP_EMAIL_VERIFICATION, signupEmailVerifySaga);
}
