import { put, call } from 'redux-saga/effects';
import axios from '../../../utils/axios';
import { BASEURL } from '../../../BaseUrl/BaseUrl';
import { startLoading, getStockManagement, getStockById, deleteStockManagement } from '../../slices/inventory';
import {
  hasError,
  setResponse,
  startFormLoading,
  getErrorMessage,
  stopFormLoading,
  getSuccessMessage,
  getDeleteMessage,
} from '../../slices/responseStatus';
import { isLogout } from '../../slices/login';

export function* inventoryStockManagmentSaga() {
  try {
    yield put(hasError(false));
    yield put(startLoading(true));
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.get(`${BASEURL}/stocks`, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    const stocksData = response?.data?.data?.stockList?.stocks;
    if (stocksData === undefined) {
      yield put(getStockManagement([]));
    } else {
      yield put(getStockManagement(stocksData));
    }
    // yield put(stopFormLoading());
  } catch (error) {
    yield put(startLoading(false));
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
    if (error.error?.statusCode === 401) {
      yield put(isLogout(true));
    }
  }
}

export function* addStockListSaga(state) {
  try {
    yield put(hasError(false));
    yield put(startFormLoading());
    const { payLoad } = state;
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.post(`${BASEURL}/stocks`, payLoad, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    yield put(getSuccessMessage(response.data.meta.message));
    yield put(setResponse(response.status));
    yield call(inventoryStockManagmentSaga);
  } catch (error) {
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
  }
}

export function* updateStockListSaga(state) {
  try {
    yield put(hasError(false));
    yield put(startFormLoading());
    const { singleField, id } = state;
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.put(`${BASEURL}/stocks/${id}`, singleField, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    yield put(getSuccessMessage(response.data.meta.message));
    yield put(setResponse(response.status));
    yield call(inventoryStockManagmentSaga);
  } catch (error) {
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
  }
}
export function* getStockListByIdSaga(state) {
  try {
    yield put(hasError(false));
    yield put(startFormLoading());
    const { id } = state;
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.get(`${BASEURL}/stocks/${id}`, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    const stocksData = response.data.data;
    yield put(getStockById(stocksData));
    yield put(stopFormLoading());
  } catch (error) {
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
  }
}

export function* stockBulkUpload(state) {
  try {
    yield put(hasError(false));
    yield put(startLoading(true));
    const { item } = state;
    const Token = window.localStorage.getItem('auth-token');
    yield axios.post(`${BASEURL}/stocks/upload`, item, {
      headers: {
        'x-auth-token': `${Token}`,
        'content-type': 'multipart/form-data',
      },
    });
    yield put(startLoading(false));
    yield call(inventoryStockManagmentSaga);
  } catch (error) {
    yield put(startLoading(false));
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
  }
}
export function* deleteStockSaga(state) {
  try {
    const { id } = state;
    yield put(hasError(false));
    yield put(startLoading(true));
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.delete(`${BASEURL}/stocks/${id}`, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    if (response.status === 200) {
      yield put(deleteStockManagement(id));
    }
    yield put(startLoading(false));
    yield put(getDeleteMessage(response.data.meta.message));
  } catch (error) {
    yield put(startLoading(false));
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
  }
}

// note:do not delete commented code. this is for multiple fields

// export function* addMultipleStockListSaga(state) {
//   try {
//     yield put(startLoading());
//     const multipleData= state.multipleData?.map((items) => ({
//         productId:items.multipleProductId,
//         quantity:items.quantity
//     }))
//     console.log(multipleData, 'dataThree in api')
//     const Token = window.localStorage.getItem('auth-token');
//     yield axios.post(`${BASEURL}/stocks`, ...multipleData, {
//       headers: {
//         'x-auth-token': `${Token}`,
//       },
//     });
//     yield call(inventoryStockManagmentSaga);
//   } catch (error) {
//     yield put(hasError());
//
//   }
// }
