import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { Box, AppBar, Toolbar, Typography, TextField } from '@mui/material';
// hook
import useOffSetTop from '../../../hooks/useOffSetTop';
import useResponsive from '../../../hooks/useResponsive';
import useSettings from '../../../hooks/useSettings';
// utils
import cssStyles from '../../../utils/cssStyles';
// config
import { HEADER, NAVBAR } from '../../../configs/config';
// components
import Logo from '../../../components/Logo';
import Iconify from '../../../components/Iconify';

import { IconButtonAnimate } from '../../../components/animate';
import { dispatch, useSelector } from '../../../redux/store';
import { getDate } from '../../../redux/slices/regionMaster';
//
import AccountPopover from './AccountPopover';
import Region from './Region';

// ----------------------------------------------------------------------

const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'isCollapse' && prop !== 'isOffset' && prop !== 'verticalLayout',
})(({ isCollapse, isOffset, verticalLayout, theme }) => ({
  ...cssStyles(theme).bgBlur(),
  boxShadow: 'none',
  borderBottom: '1px solid #ededed',
  height: HEADER.MOBILE_HEIGHT,
  zIndex: theme.zIndex.appBar + 1,
  transition: theme.transitions.create(['width', 'height'], {
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('lg')]: {
    height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
    ...(isCollapse && {
      width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
    }),
    ...(isOffset && {
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
    }),
    ...(verticalLayout && {
      width: '100%',
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
      backgroundColor: theme.palette.background.default,
    }),
  },
}));

// ----------------------------------------------------------------------

DashboardHeader.propTypes = {
  onOpenSidebar: PropTypes.func,
  isCollapse: PropTypes.bool,
  verticalLayout: PropTypes.bool,
};

export default function DashboardHeader({ onOpenSidebar, isCollapse = false, verticalLayout = false }) {
  const { title } = useSelector((state) => state.breadcrumbs);

  const { themeMode } = useSettings();
  const isOffset = useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;

  const isDesktop = useResponsive('up', 'lg');
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1;
  const currentYear = currentDate.getFullYear();

  const formattedDate = `${currentYear}-${currentMonth < 10 ? '0' : ''}${currentMonth}`;

  return (
    <RootStyle isCollapse={ isCollapse } isOffset={ isOffset } verticalLayout={ verticalLayout }>
      <Toolbar
        sx={ {
          minHeight: '100% !important',
        } }
      >
        <Box sx={ { display: 'flex', flexDirection: 'rows' } }>
          { !isDesktop && (
            <IconButtonAnimate onClick={ onOpenSidebar } sx={ { mr: 1, color: 'text.primary' } }>
              <Iconify icon="eva:menu-2-fill" />
            </IconButtonAnimate>
          ) }
          { isDesktop && verticalLayout && <Logo sx={ { mr: 2.5 } } /> }
          <Box sx={ { display: 'flex', flexDirection: 'column' } }>
            <Typography
              variant="h6"
              sx={ { color: themeMode === 'dark' ? 'white' : 'black', marginTop: '7px' } }
              gutterBottom
            >
              { title }
            </Typography>
          </Box>
        </Box>

        <Box sx={ { flexGrow: 1, marginLeft: '10px' } } />
        { title === 'Dashboard' && <Region /> }
        { title === 'Dashboard' && (
          <TextField
            size="small"
            placeholder="Month - Year"
            type="Month"
            sx={ { padding: 2 } }
            defaultValue={ formattedDate }
            onChange={ (e) => dispatch(getDate(e.target.value)) }
            InputProps={ {
              style: {
                height: "32px",
                fontSize: '14px',
                padding: 0
              }
            } }
          />
        ) }

        <Box sx={ { flexGrow: 0.1 } } />
        <AccountPopover />
      </Toolbar>
    </RootStyle>
  );
}
