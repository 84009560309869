import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import productReducer from './slices/product';
import loginReducer from './slices/login';
import dashboardReducer from './slices/dashboard';
import vendorsReducer from './slices/vendors';
import customersReducer from './slices/customers';
import employeeReducer from './slices/employee';
import leadsReducer from './slices/leadslice';
import ordersReducer from './slices/orderList';
import changepasswordReducer from './slices/changepassword';
import uploadFileReducer from './slices/uploadFile';
import breadcrumbsReducer from './slices/breadcrumbs';
import inventoryReducer from './slices/inventory';
import regionMasterReducer from './slices/regionMaster';
import responseStatusReducer from './slices/responseStatus';
import targetReducer from './slices/target';
import productListReducer from './slices/productListSlice';
import designationReducer from './slices/designation';
import forgotPasswordProcess from './slices/forgotPasswordProcess';
import signupProcess from './slices/signupProcess';
import meetingReducer from './slices/meeting';
import territoryUploadReducer from './slices/territorySlice';
import getCustomerDetailsReducer from './slices/customerDetails';
import getHolidaysDetilsReducer from './slices/notificationSetting';

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const rootReducer = combineReducers({
  product: persistReducer(productPersistConfig, productReducer),
  login: loginReducer,
  dashboard: dashboardReducer,
  vendors: vendorsReducer,
  customers: customersReducer,
  employee: employeeReducer,
  leads: leadsReducer,
  orders: ordersReducer,
  changepassword: changepasswordReducer,
  responseStatus: responseStatusReducer,
  uploadFile: uploadFileReducer,
  breadcrumbs: breadcrumbsReducer,
  inventory: inventoryReducer,
  regionMaster: regionMasterReducer,
  target: targetReducer,
  productListSlice: productListReducer,
  designation: designationReducer,
  forgotPasswordProcess,
  signupProcess,
  meeting: meetingReducer,
  customerDetails: getCustomerDetailsReducer,
  territoryUpload: territoryUploadReducer,
  notificationSetting: getHolidaysDetilsReducer,
});

export { rootPersistConfig, rootReducer };
