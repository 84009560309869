import { takeLatest } from 'redux-saga/effects';
import sagaActions from '../../actions/index';
import { regionListSaga, deleteRegionRowSaga, addNewRegion, editRegionSaga } from './regionListSaga';

export function* regionMasterSaga() {
  yield takeLatest(sagaActions.GET_REGIONLIST, regionListSaga);
  yield takeLatest(sagaActions.ADD_REGION, addNewRegion);
  yield takeLatest(sagaActions.EDIT_REGION, editRegionSaga);
  yield takeLatest(sagaActions.DELETE_REGION, deleteRegionRowSaga);
}
