import PropTypes from 'prop-types';
// @mui
import { Box } from '@mui/material';
// components
import { IconButtonAnimate } from '../../../components/animate';

// ----------------------------------------------------------------------

CollapseButton.propTypes = {
  collapseClick: PropTypes.bool,
  onToggleCollapse: PropTypes.func,
};

export default function CollapseButton({ onToggleCollapse, collapseClick }) {
  return (
    <IconButtonAnimate onClick={onToggleCollapse}>
      <Box
        sx={{
          lineHeight: 0,
          transition: (theme) =>
            theme.transitions.create('transform', {
              duration: theme.transitions.duration.shorter,
            }),
          ...(collapseClick && {
            transform: 'rotate(180deg)',
          }),
        }}
      >
        {icon}
      </Box>
    </IconButtonAnimate>
  );
}

// ----------------------------------------------------------------------

const icon = (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" className="h-4.5 w-4.5 text-gray-700 duration-300 ease-in-out">
    <path d="M10.875 9.06223L3 9.06232" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6.74537 5.31699L3 9.06236L6.74527 12.8076" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M14.1423 4L14.1423 14.125" stroke="currentColor" strokeLinecap="round" />
  </svg>
);
