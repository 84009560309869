import { call, put } from 'redux-saga/effects';
import axios from '../../../utils/axios';
import {
  getVendors,
  getdeigantionlist,
  getRoleslist,
  startLoading,
  getVendorByid,
  getVendorOrder,
  getVendorCompetitorStock,
  getChildvendore,
  getUnassignvendor,
  getAssigneduser,
  deleteVendor,
  getTerritoryList,
  getOwnStock,
  getOwnStockDetils,
  getOutstanding,
  getDistributor,
  getListOfPdfData,
  getSales,
  getMonthWiseSales,
  getVendorsCount,
  getVendorsBulkUploadResponse,
  getPdfDataResponse,
  getMonthWiseSalesListStatus,
} from '../../slices/vendors';
import { GettingRoleID } from '../../../components/GettingRoleID';
import {
  getErrorMessage,
  hasError,
  setResponse,
  startFormLoading,
  stopFormLoading,
  startAssignmentLoader,
  stopAssignmentLoader,
  getDeleteMessage,
  getSuccessMessage,
} from '../../slices/responseStatus';
import { BASEURL } from '../../../BaseUrl/BaseUrl';
import { isLogout } from '../../slices/login';

export function* vendorsListSaga(state) {
  try {
    const { designationId, rowsPerPage, page, filterName } = state;
    const searchTextParams =
      filterName && filterName.trim() !== '' ? `&searchText=${filterName}&fieldname=config.company,contact_no` : '';
    const sortParams = `&sort=asc&column=config.company`;

    yield put(startLoading(true));
    yield put(startFormLoading());
    yield put(hasError(false));
    const id = GettingRoleID('vendor');

    const url = `${BASEURL}/users/?role=${id}&designation=${designationId}&limit=${rowsPerPage}&page=${
      page + 1
    }${searchTextParams}${sortParams}`;
    // if (process.env.REACT_APP_IS_MEGHMANI) {
    //   url = `${BASEURL}/users/?role=${id}&designation=fe8dacfa-1c23-480d-89ac-0c242bde8e4b`;
    // }

    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.get(url, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    const userData = response?.data?.data?.users?.user;
    const count = response?.data?.data?.users?.totalRowsValue;
    if (userData === undefined) {
      yield put(getVendors([]));
    } else {
      yield put(getVendors(userData));
      yield put(getVendorsCount(count));
    }
    yield put(stopFormLoading());
  } catch (error) {
    yield put(hasError(true));
    yield put(startLoading(false));
    yield put(getVendors([]));
    yield put(getErrorMessage(error.error.message));
    if (error.error?.statusCode === 401) {
      yield put(isLogout(true));
    }
  }
}
export function* vendorListSagaForView(state) {
  try {
    const { designationId, rowsPerPage, page, model } = state;
    yield put(hasError(false));
    yield put(startLoading(true));
    yield put(startFormLoading());

    const id = GettingRoleID('vendor');
    const url = `${BASEURL}/users/?role=${id}&designation=${designationId}`;
    const Token = window.localStorage.getItem('auth-token');
    const response = yield call(axios.get, url, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });

    const user = response?.data?.data?.users?.user;
    const count = response?.data?.data?.users?.totalRowsValue;
    if (user === undefined) {
      yield put(getVendors({ user: [], count: 0 }));
    } else {
      yield put(getVendors({ user, count }));
    }
    yield put(stopFormLoading());
  } catch (error) {
    yield put(startLoading(false));
    yield put(stopFormLoading());

    yield put(getVendors({ user: [], count: 0 }));
    yield put(hasError(true));
    yield put(getErrorMessage(error.message));
    if (error?.response?.status === 401) {
      yield put(isLogout(true));
    }
  }
}

export function* vendorOrderSaga(state) {
  try {
    const { id } = state;
    yield put(startLoading(true));
    yield put(startFormLoading());
    yield put(hasError(false));
    const url = `${BASEURL}/salescollection/${id}`;
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.get(url, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    const userData = response?.data?.data?.users?.user;
    if (userData === undefined) {
      yield put(getVendors([]));
    } else {
      yield put(getVendors(userData));
    }
    yield put(stopFormLoading());
  } catch (error) {
    yield put(hasError(true));
    yield put(startLoading(false));
    yield put(getVendors([]));
    yield put(getErrorMessage(error.error.message));
    if (error.error?.statusCode === 401) {
      yield put(isLogout(true));
    }
  }
}
export function* ownStockDetilsSaga(state) {
  try {
    const { designationId, productId } = state;
    yield put(startLoading(true));
    yield put(startFormLoading());
    yield put(hasError(false));
    const url = `${BASEURL}/salescollection/liquidation/${designationId}/${productId}`;
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.get(url, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    const { liquidation } = response?.data?.data;
    if (liquidation === undefined) {
      yield put(getOwnStockDetils([]));
    } else {
      yield put(getOwnStockDetils(liquidation));
    }
    yield put(stopFormLoading());
  } catch (error) {
    yield put(hasError(true));
    yield put(startLoading(false));
    yield put(getVendors([]));
    yield put(getErrorMessage(error.error.message));
    if (error.error?.statusCode === 401) {
      yield put(isLogout(true));
    }
  }
}
export function* DeleteVendorRowSaga(state) {
  try {
    yield put(startLoading(true));
    yield put(hasError(false));
    const { id } = state;

    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.delete(`${BASEURL}/users/${id}`, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    if (response.status === 200) {
      yield put(deleteVendor(id));
    }

    // yield call(vendorsListSaga);
    yield put(startLoading(false));
    yield put(getDeleteMessage(response.data.meta.message));
  } catch (error) {
    yield put(startLoading(false));
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
  }
}

export function* addVendorSaga(state) {
  try {
    yield put(hasError(false));
    yield put(startFormLoading());
    const { item } = state;

    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.post(`${BASEURL}/users`, item, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    yield put(getSuccessMessage(response.data.meta.message));
    yield put(setResponse(response.status));
    yield call(vendorsListSaga);
  } catch (error) {
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
  }
}

export function* editVendorSaga(state) {
  try {
    yield put(hasError(false));

    const { index, item, statusChange } = state;
    if (statusChange !== 'statusChange') {
      yield put(startFormLoading());
    }

    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.put(`${BASEURL}/users/${index}`, item, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });

    if (statusChange !== 'statusChange') {
      yield put(setResponse(response.status));
    }

    yield call(vendorsListSaga);
  } catch (error) {
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
  }
}

export function* getdesignation() {
  try {
    yield put(hasError(false));
    yield put(startFormLoading());
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.get(`${BASEURL}/designations/?dept=vendor`, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    const designationData = response.data.data.Designations.designation;
    yield put(getdeigantionlist(designationData === undefined ? [] : designationData));
    yield put(stopFormLoading());
  } catch (error) {
    yield put(hasError(true));
    yield put(stopFormLoading());
    yield put(startLoading(false));
    yield put(getErrorMessage(error.error.message));
  }
}

export function* getpdfData(state) {
  try {
    const { payload } = state;
    yield put(hasError(false));
    yield put(startLoading(true));
    yield put(startFormLoading());

    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.get(`${BASEURL}/salescollection/customersales/${payload.id}?pdf=${true}`, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    yield put(startLoading(false));
    const { file } = response.data;
    yield put(getListOfPdfData(file === undefined ? [] : file));
    yield put(getPdfDataResponse(response?.status));
    yield put(stopFormLoading());
  } catch (error) {
    yield put(hasError(true));
    yield put(stopFormLoading());
    yield put(startLoading(false));
    yield put(getErrorMessage(error.error.message));
  }
}
export function* getTerritory() {
  try {
    yield put(hasError(false));
    yield put(startFormLoading());
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.get(`${BASEURL}/territorymaster`, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    const territories = response.data.data.territoryMaster;
    yield put(getTerritoryList(territories === undefined ? [] : territories));
    yield put(stopFormLoading());
  } catch (error) {
    yield put(hasError(true));
    yield put(stopFormLoading());
    yield put(startLoading(false));
    yield put(getErrorMessage(error.error.message));
  }
}

export function* getRoles() {
  try {
    yield put(hasError(false));
    yield put(startFormLoading());
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.get(`${BASEURL}/roles`, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    const { Roles } = response.data.data;
    if (Roles) {
      window.localStorage.setItem('roles', JSON.stringify(Roles));
    } else {
      window.localStorage.setItem('roles', JSON.stringify([]));
    }
    yield put(getRoleslist(Roles));
    // yield call(employeeListSaga);
    yield put(stopFormLoading());
  } catch (error) {
    yield put(hasError(true));
    yield put(startLoading(false));
    yield put(stopFormLoading());
    yield put(getErrorMessage(error.error.message));
  }
}

export function* gerVendorDetail(state) {
  try {
    const { id, model, startDate, endDate } = state;
    yield put(hasError(false));
    yield put(startFormLoading());
    const Token = window.localStorage.getItem('auth-token');
    if (model === 'Detail') {
      const getUserByID = yield axios.get(`${BASEURL}/users/${id}`, {
        headers: {
          'x-auth-token': `${Token}`,
        },
      });
      const { user } = getUserByID.data.data;
      yield put(getVendorByid(user[0]));
    }
    if (model === 'Orders') {
      const response = yield axios.get(`${BASEURL}/salescollection/${id}`, {
        headers: {
          'x-auth-token': `${Token}`,
        },
      });
      const { data } = response.data.data;
      yield put(getVendorOrder(data === undefined ? [] : data));
    }
    if (model === 'Outstanding') {
      const response = yield axios.get(`${BASEURL}/salescollection/customersales/${id}`, {
        headers: {
          'x-auth-token': `${Token}`,
        },
      });
      const { transactions, openingBalance } = response.data.data;
      yield put(getOutstanding(transactions === undefined ? [] : { transactions, openingBalance }));
    }
    if (model === 'Competitor Stock') {
      const response = yield axios.get(`${BASEURL}/salescollection/competitorsotck/${id}`, {
        headers: {
          'x-auth-token': `${Token}`,
        },
      });
      const { data } = response.data.data;
      yield put(getVendorCompetitorStock(data === undefined ? [] : data));
    }
    if (model === 'Own Stock') {
      const response = yield axios.get(`${BASEURL}/salescollection/productlist/${id}`, {
        headers: {
          'x-auth-token': `${Token}`,
        },
      });
      const { product } = response.data.data;
      yield put(getOwnStock(product === undefined ? [] : product));
    }

    yield put(stopFormLoading());
  } catch (error) {
    yield put(hasError(true));

    yield put(getErrorMessage(error.error.message));
  }
}

export function* vendorBulkUpload(state) {
  try {
    yield put(startLoading(true));
    yield put(hasError(false));
    const { item } = state;
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.post(`${BASEURL}/users/uploadcustomer`, item, {
      headers: {
        'x-auth-token': `${Token}`,
        'content-type': 'multipart/form-data',
      },
    });
    if (response.status === 200) {
      yield put(getSuccessMessage(response.data.meta.message));
      yield put(setResponse(response.status));
      yield put(getVendorsBulkUploadResponse(response?.status));
    }
    yield put(startLoading(false));
  } catch (error) {
    yield put(startLoading(false));
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
  }
}
export function* dailySalesCollectionBulkUpload(state) {
  try {
    yield put(startLoading(true));
    yield put(hasError(false));
    const { item } = state;
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.post(`${BASEURL}/salescollection/dailycollection/upload`, item, {
      headers: {
        'x-auth-token': `${Token}`,
        'content-type': 'multipart/form-data',
      },
    });
    if (response.status === 200) {
      yield put(getSuccessMessage(response.data.meta.message));
      yield put(setResponse(response.status));
    }
    yield put(startLoading(false));
    //  yield call(vendorsListSaga);
  } catch (error) {
    yield put(startLoading(false));
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
  }
}
export function* salesCustomerBulkUpload(state) {
  try {
    yield put(startLoading(true));
    yield put(hasError(false));
    const { item } = state;
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.post(`${BASEURL}/salescollection/upload`, item, {
      headers: {
        'x-auth-token': `${Token}`,
        'content-type': 'multipart/form-data',
      },
    });
    if (response.status === 200) {
      yield put(getSuccessMessage(response.data.meta.message));
      yield put(setResponse(response.status));
    }
    yield put(startLoading(false));
    // yield call(vendorsListSaga);
  } catch (error) {
    yield put(startLoading(false));
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
  }
}

export function* vendorHierarchy(state) {
  try {
    const { DesignationID } = state;
    yield put(hasError(false));
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.get(`${BASEURL}/hierarchies/designation/${DesignationID}?dept=vendor`, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });

    const { designations } = response.data.data;

    yield put(getChildvendore(designations[0]));
    // yield call(vendorsListSaga);
  } catch (error) {
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
  }
}

export function* assignunassignevendore(state) {
  try {
    yield put(startAssignmentLoader());
    const { childDesignationID, currentUserID } = state;
    yield put(hasError(false));
    const Token = window.localStorage.getItem('auth-token');
    if (currentUserID && (currentUserID !== undefined || null)) {
      const getUnassignuser = yield axios.get(
        `${BASEURL}/userManagement/unassigned/?child_designation_id=${childDesignationID}&user_id=${currentUserID}&role=vendor`,
        {
          headers: {
            'x-auth-token': `${Token}`,
          },
        }
      );
      const data = getUnassignuser.data.data.Unassigned_users;
      yield put(getUnassignvendor(data === undefined ? [] : data));
    } else {
      const getUnassignuser = yield axios.get(
        `${BASEURL}/userManagement/unassigned/?child_designation_id=${childDesignationID}&role=vendor`,
        {
          headers: {
            'x-auth-token': `${Token}`,
          },
        }
      );
      const data = getUnassignuser.data.data.Unassigned_users;
      yield put(getUnassignvendor(data === undefined ? [] : data));
    }
    if (currentUserID !== undefined || null) {
      const getAssignuser = yield axios.get(
        `${BASEURL}/userManagement/vendors/${currentUserID}?designation_id=${childDesignationID}&role=vendor`,
        {
          headers: {
            'x-auth-token': `${Token}`,
          },
        }
      );
      const assigneduser = getAssignuser.data.data.Vendors;
      yield put(getAssigneduser(assigneduser === undefined ? [] : assigneduser));
    }

    yield put(stopAssignmentLoader());
  } catch (error) {
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
  }
}

export function* getCompetitorStockData(state) {
  try {
    const { id } = state;
    yield put(startLoading(true));
    yield put(startFormLoading());
    yield put(hasError(false));
    const url = `${BASEURL}/salescollection/competitorsotck/${id}`;
    // if (startDate && endDate) {
    //   url += `?startDate=${startDate}&endDate=${endDate}`;
    // }
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.get(url, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    const { data } = response.data.data;
    if (data === undefined) {
      yield put(getVendorCompetitorStock([]));
    } else {
      yield put(getVendorCompetitorStock(data));
    }
    yield put(stopFormLoading());
  } catch (error) {
    yield put(hasError(true));
    yield put(startLoading(false));
    yield put(getVendorCompetitorStock([]));
    yield put(getErrorMessage(error.error.message));
    if (error.error?.statusCode === 401) {
      yield put(isLogout(true));
    }
  }
}

export function* getDistributorData(state) {
  try {
    const { userId, startDate, endDate } = state;
    yield put(startLoading(true));
    yield put(startFormLoading());
    yield put(hasError(false));
    let url = `${BASEURL}/salescollection/customersales/${userId}`;
    if (startDate && endDate) {
      url += `?startDate=${startDate}&endDate=${endDate}`;
    }
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.get(url, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    const userData = response?.data?.data?.data;
    if (userData === undefined) {
      yield put(getDistributor([]));
    } else {
      yield put(getDistributor(userData));
    }
    yield put(stopFormLoading());
  } catch (error) {
    yield put(hasError(true));
    yield put(startLoading(false));
    yield put(getDistributor([]));
    yield put(getErrorMessage(error.error.message));
    if (error.error?.statusCode === 401) {
      yield put(isLogout(true));
    }
  }
}
export function* vendorOwnStockSaga(state) {
  try {
    const { id } = state;
    yield put(startLoading(true));
    yield put(startFormLoading());
    yield put(hasError(false));
    const url = `${BASEURL}/salescollection/productlist/${id}`;
    // if (startDate && endDate) {
    //   url += `?startDate=${startDate}&endDate=${endDate}`;
    // }
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.get(url, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    const { product } = response.data.data;
    yield put(getOwnStock(product === undefined ? [] : product));
    if (product === undefined) {
      yield put(getSales([]));
    } else {
      yield put(getSales(product));
    }
    yield put(stopFormLoading());
  } catch (error) {
    yield put(hasError(true));
    yield put(startLoading(false));
    yield put(getVendors([]));
    yield put(getErrorMessage(error.error.message));
    if (error.error?.statusCode === 401) {
      yield put(isLogout(true));
    }
  }
}
export function* vendorSalesSaga(state) {
  try {
    const { payload } = state;
    yield put(startLoading(true));
    yield put(startFormLoading());
    yield put(hasError(false));
    const url = `${BASEURL}/salescollection/${payload.id}`;
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.get(url, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    const userData = response?.data?.data?.data;
    if (userData === undefined) {
      yield put(getSales([]));
    } else {
      yield put(getSales(userData));
    }
    yield put(stopFormLoading());
  } catch (error) {
    yield put(hasError(true));
    yield put(startLoading(false));
    yield put(getVendors([]));
    yield put(getErrorMessage(error.error.message));
    if (error.error?.statusCode === 401) {
      yield put(isLogout(true));
    }
  }
}
export function* vendorMonthWiseSalesSaga(state) {
  try {
    const { payload } = state;
    yield put(startLoading(true));
    yield put(startFormLoading());
    yield put(hasError(false));
    const url = `${BASEURL}/salescollection/monthwisesales/${payload.id}`;
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.get(url, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    const userData = response?.data?.data?.data;
    if (userData === undefined) {
      yield put(getMonthWiseSales([]));
    } else {
      yield put(getMonthWiseSales(userData));
      yield put(getMonthWiseSalesListStatus(response?.status));
    }
    yield put(stopFormLoading());
  } catch (error) {
    yield put(hasError(true));
    yield put(startLoading(false));
    yield put(getVendors([]));
    yield put(getErrorMessage(error.error.message));
    if (error.error?.statusCode === 401) {
      yield put(isLogout(true));
    }
  }
}
