import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  error: false,
  errorMessage: '',
  productList: [],
  productCategory: [],
  categoryCount: undefined,
  stockManagement: [],
  priceListData: [],
  response: '',
  productById: {},
  assignedEmployeesbyProductId: [],
  unAssignedEmployeesbyProductId: [],
  ProductForStock: [],
  stockById: {},
};
const inventory = createSlice({
  name: 'inventory',
  initialState,
  reducers: {
    startLoading(state, action) {
      state.isLoading = action.payload;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    deleteProductList(state, action) {
      state.productList = state.productList.filter((data) => data.id !== action.payload);
    },
    getProductCategory(state, action) {
      state.isLoading = false;
      state.productCategory = action.payload.category;
      state.categoryCount = action.payload.totalRowsValue;
    },
    pushNewCategory(state, action) {
      state.isLoading = false;
      state.productCategory = [action.payload, ...state.productCategory];
    },
    editCategory(state, action) {
      state.isLoading = false;
      state.productCategory = state.productCategory.map((obj) => {
        if (obj.id === action.payload.id) {
          return {
            ...obj,
            category_name: action.payload.data.category_name,
            status: action.payload.data.status,
            id: action.payload.id,
          };
        }
        return obj;
      });
    },
    changeProductStatus(state, action) {
      state.isLoading = false;
      state.productList = state.productList.map((obj) => {
        if (obj.id === action.payload.id) {
          return {
            ...obj,
            status: action.payload.data.status,
            id: action.payload.id,
          };
        }
        return obj;
      });
    },
    deleteCategory(state, action) {
      state.productCategory = state.productCategory.filter((data) => data.id !== action.payload);
    },
    getStockManagement(state, action) {
      state.isLoading = false;
      state.stockManagement = action.payload;
    },
    deleteStockManagement(state, action) {
      state.stockManagement = state.stockManagement.filter((data) => data.stock_id !== action.payload);
    },
    editProduct(state, action) {
      state.productList = action.payload;
    },
    pushNewProduct(state, action) {
      state.isLoading = false;
      state.productList = [...state.productList, action.payload];
    },
    pushProductsInBulk(state, action) {
      state.isLoading = false;
      state.productList = [...state.productList, ...action.payload];
    },

    addNewStock(state, action) {
      state.isLoading = false;
      state.stockManagement = [...state.stockManagement, ...action.payload];
      state.error = false;
    },
    editStock(state, action) {
      state.isLoading = false;
      state.stockManagement = action.payload;
      state.error = false;
    },
    pushNewStockInBulk(state, action) {
      state.isLoading = false;
      state.stockManagement = [...state.stockManagement, ...action.payload];
    },
    getPriceListData(state, action) {
      state.isLoading = false;
      state.priceListData = action.payload;
      state.error = false;
    },
    editPriceListData(state, action) {
      state.isLoading = false;
      state.priceListData = action.payload;
      state.error = false;
    },
    deletePriceList(state, action) {
      state.priceListData = state.priceListData.filter((data) => data.id !== action.payload);
    },
    setResponse(state, action) {
      state.isLoading = false;
      state.response = action.payload;
    },
    getProductById(state, action) {
      state.isLoading = false;
      state.productById = action.payload;
    },
    getAssignedEmployeesByProductId(state, action) {
      state.isLoading = false;
      state.assignedEmployeesbyProductId = action.payload;
    },
    getUnAssignedEmployeesByProductId(state, action) {
      state.isLoading = false;
      state.unAssignedEmployeesbyProductId = action.payload;
    },
    getProductForStock(state, action) {
      state.ProductForStock = [...action.payload];
    },
    getStockById(state, action) {
      state.isLoading = false;
      state.stockById = action.payload;
      state.error = false;
    },
  },
});

export default inventory.reducer;
export const {
  startLoading,
  hasError,
  getProduct,
  getProductCategory,
  getStockManagement,
  pushNewCategory,
  pushNewProduct,
  editCategory,
  editProduct,
  pushProductsInBulk,
  addNewStock,
  deleteProductList,
  editStock,
  pushNewStockInBulk,
  getPriceListData,
  editPriceListData,
  // pushNewPriceList,
  changeProductStatus,
  setResponse,
  getProductById,
  getAssignedEmployeesByProductId,
  getUnAssignedEmployeesByProductId,
  deleteCategory,
  deletePriceList,
  getProductForStock,
  getStockById,
  deleteStockManagement,
} = inventory.actions;
