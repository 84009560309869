import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  error: null,
  regionMaster: [],
  region: '',
  date: '',
  month: '',
  year: '',
};
const regionMaster = createSlice({
  name: 'regionMaster',
  initialState,
  reducers: {
    startLoading(state, action) {
      state.isLoading = action.payload;
    },
    hasError(state) {
      state.isLoading = false;
      state.error = true;
    },
    getRegionMaster(state, action) {
      state.isLoading = false;
      state.regionMaster = action.payload;
      state.error = false;
    },
    deleteRegionSuccess(state, action) {
      state.regionMaster = state.regionMaster.filter((item) => item.id !== action.payload);
    },
    addNewRegionSuccess(state, action) {
      state.regionMaster = [...action.payload, ...state.regionMaster];
    },
    editRegionSuccess(state, action) {
      state.isLoading = false;
      state.regionMaster = state.regionMaster.map((obj) => {
        if (obj?.id === action.payload.id) {
          return {
            ...obj,
            region: action.payload.data.region,
            id: action.payload.id,
          };
        }
        return obj;
      });
    },
    getRegionvalue(state, action) {
      state.region = action.payload;
    },
    getDate(state, action) {
      state.year = action.payload?.slice(0, 4);
      state.date = action.payload;
      state.month = action.payload?.slice(5);
    },
  },
});

export default regionMaster.reducer;
export const {
  startLoading,
  hasError,
  getRegionMaster,
  deleteRegionSuccess,
  addNewRegionSuccess,
  editRegionSuccess,
  getRegionvalue,
  getDate,
} = regionMaster.actions;
