import { put } from 'redux-saga/effects';
import axios from '../../../utils/axios';
import {
  getPendingOrderList,
  getCancelledOrderList,
  getConfirmOrderList,
  startLoading,
  getOrderDetails,
  getAllOrderList,
  getRejectedList,
  downLoadOrderReport,
  stopLoading,
} from '../../slices/orderList';
import { BASEURL } from '../../../BaseUrl/BaseUrl';
import { stopFormLoading, startFormLoading, hasError, getErrorMessage, setResponse } from '../../slices/responseStatus';
import { isLogout } from '../../slices/login';

export function* orderListSaga(state) {
  try {
    yield put(hasError(false));
    const { startDate, endDate } = state;
    yield put(startLoading());
    const modal = localStorage.getItem('orderModal');
    const Token = window.localStorage.getItem('auth-token');
    if (modal === 'pending') {
      const pendingResponse = yield axios.get(
        `${BASEURL}/userActivities/status?event_type=order&status=Pending&startDate=${startDate}&endDate=${endDate}`,
        {
          headers: {
            'x-auth-token': `${Token}`,
            'Cache-Control': 'no-cache',
          },
        }
      );
      const pendingOrders = pendingResponse?.data?.data?.UserActivities?.order;
      yield put(getPendingOrderList(pendingOrders === undefined ? [] : pendingOrders));
      yield put(downLoadOrderReport(pendingOrders === undefined ? [] : pendingOrders));
    } else if (modal === 'confirm') {
      const confirmResponse = yield axios.get(
        `${BASEURL}/userActivities/status?event_type=order&status=Approved&startDate=${startDate}&endDate=${endDate}`,
        {
          headers: {
            'x-auth-token': `${Token}`,
          },
        }
      );
      const confirmOrders = confirmResponse?.data?.data?.UserActivities?.order;
      yield put(getConfirmOrderList(confirmOrders === undefined ? [] : confirmOrders));
      yield put(downLoadOrderReport(confirmOrders === undefined ? [] : confirmOrders));
    } else if (modal === 'reject') {
      const rejectedResponse = yield axios.get(
        `${BASEURL}/userActivities/status?event_type=order&status=Rejected&startDate=${startDate}&endDate=${endDate}`,
        {
          headers: {
            'x-auth-token': `${Token}`,
          },
        }
      );
      const rejectedOrders = rejectedResponse?.data?.data?.UserActivities?.order;
      yield put(getRejectedList(rejectedOrders === undefined ? [] : rejectedOrders));
      yield put(downLoadOrderReport(rejectedOrders === undefined ? [] : rejectedOrders));
    } else if (modal === 'cancel') {
      const cancelResponse = yield axios.get(
        `${BASEURL}/userActivities/status?event_type=order&status=Cancelled&startDate=${startDate}&endDate=${endDate}`,
        {
          headers: {
            'x-auth-token': `${Token}`,
          },
        }
      );
      const cancelOrders = cancelResponse?.data?.data?.UserActivities?.order;
      yield put(getCancelledOrderList(cancelOrders === undefined ? [] : cancelOrders));
      yield put(downLoadOrderReport(cancelOrders === undefined ? [] : cancelOrders));
    } else if (modal === 'all') {
      const allResponse = yield axios.get(
        `${BASEURL}/userActivities/status?event_type=order&startDate=${startDate}&endDate=${endDate}`,
        {
          headers: {
            'x-auth-token': `${Token}`,
          },
        }
      );
      const allOrders = allResponse?.data?.data?.UserActivities?.order;

      yield put(getAllOrderList(allOrders === undefined ? [] : allOrders));
      yield put(downLoadOrderReport(allOrders === undefined ? [] : allOrders));
      yield put(stopLoading());
    }
  } catch (error) {
    yield put(hasError(true));
    yield put(stopLoading());
    yield put(getErrorMessage(error.error.message));
    if (error.error?.statusCode === 401) {
      yield put(isLogout(true));
    }
  }
}

export function* orderByIdSaga(state) {
  try {
    yield put(hasError(false));
    yield put(startFormLoading());
    const { id } = state;
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.get(`${BASEURL}/userActivities/${id}`, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    const orderDetailsResponse = response.data.data.UserActivities;
    yield put(getOrderDetails(orderDetailsResponse === undefined ? [] : orderDetailsResponse));
    yield put(stopFormLoading());
  } catch (error) {
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
  }
}

export function* changeOrderStatusSaga(state) {
  try {
    yield put(hasError(false));
    yield put(startLoading());
    const { id, data } = state;
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.put(`${BASEURL}/userActivities/status/${id}`, data, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    yield put(setResponse(response.status));
  } catch (error) {
    yield put(stopLoading());
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
  }
}

export function* downloadOrderReportSaga(state) {
  try {
    yield put(hasError(false));
    // yield put(startLoading());
    const { startDate, endDate } = state;

    const Token = window.localStorage.getItem('auth-token');

    const response = yield axios.get(
      `${BASEURL}/userActivities/reportDownload?event_type=order&startDate=${startDate}&endDate=${endDate}`,
      {
        headers: {
          'x-auth-token': `${Token}`,
        },
      }
    );
    const datas = response.data.data.UserActivities;
    yield put(downLoadOrderReport(datas === undefined ? [] : datas));
  } catch (error) {
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
  }
}
