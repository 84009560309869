import { takeLatest } from 'redux-saga/effects';
import sagaActions from '../../actions/index';
import {
  orderListSaga,
  orderByIdSaga,
  downloadOrderReportSaga,
  changeOrderStatusSaga,
  // eslint-disable-next-line camelcase
} from './orderListSaga';

export function* ordersSaga() {
  yield takeLatest(sagaActions.GET_ORDERLIST, orderListSaga);
  yield takeLatest(sagaActions.GET_ORDERDETAILS, orderByIdSaga);
  yield takeLatest(sagaActions.CHANGE_ORDER_STATUS, changeOrderStatusSaga);

  yield takeLatest(sagaActions.DOWNLOAD_ORDERREPORT, downloadOrderReportSaga);
}
