import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  error: null,
  vendors: [],
  designation: [],
  currentVendor: null,
  childvendor: null,
  unassignvendor: [],
  assigneduser: [],
  orderOfVendor: [],
  vendorCompetitorStockInfo: [],
  roles: [],
  OwnStockInfo: [],
  response: '',
  territories: [],
  OutstandingReport: [],
  OutstandingOpeningBalance: [],
  OwnStockDetils: [],
  distributor: [],
  pdfdata: [],
  salesCustomerUpload: [],
  dailyCollectionUpload: [],
  salesListDetails: [],
  MonthWiseSalesListDetails: [],
  vendorsCount: undefined,
  vendorsBulkUploadResponse: '',
  pdfDataResponse: '',
  monthWiseSalesListStatus: '',
};
const vendors = createSlice({
  name: 'vendors',
  initialState,
  reducers: {
    startLoading(state, action) {
      state.isLoading = action.payload;
    },
    hasError(state, action) {
      state.error = action.payload;
    },
    deleteVendor(state, action) {
      state.vendors = state.vendors.filter((data) => data.id !== action.payload);
    },
    getVendors(state, action) {
      state.vendors = action.payload;
      state.error = false;
      state.isLoading = false;
    },
    getVendorsCount(state, action) {
      state.isLoading = false;
      state.vendorsCount = action.payload;
    },
    getVendorByid(state, action) {
      state.isLoading = false;
      state.currentVendor = action.payload;
    },
    getVendorOrder(state, action) {
      state.isLoading = false;
      state.orderOfVendor = action.payload;
    },
    getOutstanding(state, action) {
      state.isLoading = false;
      state.OutstandingReport = action.payload.transactions;
      state.OutstandingOpeningBalance = action.payload.openingBalance;
    },
    getSales(state, action) {
      state.isLoading = false;
      state.salesListDetails = action.payload;
    },
    getMonthWiseSales(state, action) {
      state.isLoading = false;
      state.MonthWiseSalesListDetails = action.payload;
    },
    getVendorCompetitorStock(state, action) {
      state.isLoading = false;
      state.vendorCompetitorStockInfo = action.payload;
    },
    getOwnStock(state, action) {
      state.isLoading = false;
      state.OwnStockInfo = action.payload;
    },
    getOwnStockDetils(state, action) {
      state.isLoading = false;
      state.OwnStockDetils = action.payload;
    },
    vendorsBulkUpload(state, action) {
      state.isLoading = false;
      state.vendors = [...state.vendors, ...action.payload];
    },
    salesCustomerBulkUpload(state, action) {
      state.isLoading = false;
      state.salesCustomerUpload = [...state.vendors, ...action.payload];
    },
    dailySalesCollectionBulkUpload(state, action) {
      state.isLoading = false;
      state.dailyCollectionUpload = [...state.vendors, ...action.payload];
    },
    getdeigantionlist(state, action) {
      state.isLoading = false;
      state.designation = action.payload;
    },
    getRoleslist(state, action) {
      state.isLoading = false;
      state.roles = action.payload;
    },
    setResponse(state, action) {
      state.isLoading = false;
      state.response = action.payload;
    },
    getChildvendore(state, action) {
      state.childvendor = action.payload;
    },
    getUnassignvendor(state, action) {
      state.unassignvendor = action.payload;
    },
    getAssigneduser(state, action) {
      state.assigneduser = action.payload;
    },
    getTerritoryList(state, action) {
      state.territories = action.payload;
    },
    getListOfPdfData(state, action) {
      state.pdfdata = action.payload;
    },
    getPdfDataResponse(state, action) {
      state.pdfDataResponse = action.payload;
    },
    getDistributor(state, action) {
      state.isLoading = false;
      state.distributor = action.payload;
    },
    getVendorsBulkUploadResponse(state, action) {
      state.vendorsBulkUploadResponse = action.payload;
      state.isLoading = false;
    },
    getMonthWiseSalesListStatus(state, action) {
      state.monthWiseSalesListStatus = action.payload;
      state.isLoading = false;
    },
  },
});

export default vendors.reducer;
export const {
  startLoading,
  hasError,
  getVendors,
  getVendorOrder,
  getVendorCompetitorStock,
  vendorsBulkUpload,
  getdeigantionlist,
  deleteVendor,
  getRoleslist,
  setResponse,
  getVendorByid,
  getChildvendore,
  getUnassignvendor,
  getAssigneduser,
  getTerritoryList,
  getOwnStock,
  getDistributor,
  getOwnStockDetils,
  getOutstanding,
  getListOfPdfData,
  salesCustomerBulkUpload,
  dailySalesCollectionBulkUpload,
  getSales,
  getMonthWiseSales,
  getVendorsCount,
  getVendorsBulkUploadResponse,
  getPdfDataResponse,
  getMonthWiseSalesListStatus,
} = vendors.actions;
