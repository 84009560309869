import { takeLatest } from 'redux-saga/effects';
import sagaActions from '../../actions/index';
import {
  inventoryProductListSaga,
  addNewProduct,
  deleteProduct,
  updateProduct,
  productBulkUpload,
  getProductByIdSaga,
  assignedEmployeeByProductIdSaga,
  unAssignedEmployeeByProductIdSaga,
  changeProductStatusSaga,
} from './productListSaga';
import { getPriceList, addnewPriceList, deletePrice, updatePrice } from './priceListSaga';
import {
  addNewCategorySaga,
  inventoryProductCategorySaga,
  deleteCategorySaga,
  editCategorySaga,
  changeCategoryStatusSaga,
} from './productCategorySaga';
import {
  addStockListSaga,
  inventoryStockManagmentSaga,
  stockBulkUpload,
  updateStockListSaga,
  getStockListByIdSaga,
  deleteStockSaga,
} from './stockManagmentSaga';

export function* inventorySaga() {
  yield takeLatest(sagaActions.GET_PRODUCT, inventoryProductListSaga);
  yield takeLatest(sagaActions.GET_PRICELIST, getPriceList);
  yield takeLatest(sagaActions.ADD_PRICE, addnewPriceList);
  yield takeLatest(sagaActions.ADD_PRODUCT, addNewProduct);
  yield takeLatest(sagaActions.DELETE_PRODUCT, deleteProduct);
  yield takeLatest(sagaActions.DELETE_PRICE, deletePrice);
  yield takeLatest(sagaActions.EDIT_PRODUCT, updateProduct);
  yield takeLatest(sagaActions.EDIT_PRICE, updatePrice);
  yield takeLatest(sagaActions.GET_PRODUCT_CATEGORY, inventoryProductCategorySaga);
  yield takeLatest(sagaActions.ADD_CATEGORY, addNewCategorySaga);
  yield takeLatest(sagaActions.EDIT_CATEGORY, editCategorySaga);
  yield takeLatest(sagaActions.DELETE_CATEGORY, deleteCategorySaga);
  yield takeLatest(sagaActions.CHANGE_STATUS, changeCategoryStatusSaga);
  yield takeLatest(sagaActions.GET_STOCKES, inventoryStockManagmentSaga);
  yield takeLatest(sagaActions.ADD_PRODUCT_STOCK, addStockListSaga);
  yield takeLatest(sagaActions.PRODUCT_BULKUPLOAD, productBulkUpload);
  yield takeLatest(sagaActions.STOCK_BULKUPLOAD, stockBulkUpload);
  yield takeLatest(sagaActions.GET_PRODUCT_BY_ID, getProductByIdSaga);
  yield takeLatest(sagaActions.ASSIGNED_EMPLOYEES_BY_PRODUCTID, assignedEmployeeByProductIdSaga);
  yield takeLatest(sagaActions.ASSIGNED_EMPLOYEES_BY_PRODUCTID, unAssignedEmployeeByProductIdSaga);
  yield takeLatest(sagaActions.CHANGE_PRODUCT_STATUS, changeProductStatusSaga);
  yield takeLatest(sagaActions.UPDATE_STOCKLIST, updateStockListSaga);
  yield takeLatest(sagaActions.GET_STOCK_BY_ID, getStockListByIdSaga);
  yield takeLatest(sagaActions.DELETE_STOCK, deleteStockSaga);

  // yield takeLatest(sagaActions.ADD_MULTIPLE_STOCK, addMultipleStockListSaga);
}
