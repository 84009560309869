import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  error: null,
  customerDetails: [],
  downloadCustomerDetails: [],
  customerDetailsCount: undefined,
  customerDetailsStatus: undefined,
};
const customerDetails = createSlice({
  name: 'customerDetails',
  initialState,
  reducers: {
    startLoading(state, action) {
      state.isLoading = action.payload;
    },
    hasError(state, action) {
      state.error = action.payload;
    },
    getCustomerDetails(state, action) {
      state.isLoading = false;
      state.customerDetails = action.payload;
      state.error = false;
    },
    downloadCustomerDetails(state, action) {
      state.isLoading = false;
      state.downloadCustomerDetails = action.payload;
      state.error = false;
    },
    getCustomerDetailsStatus(state, action) {
      state.customerDetailsStatus = action.payload;
    },
    getCustomerDetailsCount(state, action) {
      state.customerDetailsCount = action.payload;
    },
  },
});

export default customerDetails.reducer;
export const {
  startLoading,
  hasError,
  getCustomerDetails,
  getCustomerDetailsCount,
  getCustomerDetailsStatus,
  downloadCustomerDetails,
} = customerDetails.actions;
