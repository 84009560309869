import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  error: false,
  errorMessage: '',
  forgotPasswordResponse: '',
  verifyOtpResponse: '',
  newPasswordResponse: '',
  changePasswordResponse: '',
};
const forgotPassword = createSlice({
  name: 'forgotPassword',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    forgotPasswordSuccessResponse(state, action) {
      state.isLoading = false;
      state.forgotPasswordResponse = action.payload;
    },
    verifyOtpSuccessResponse(state, action) {
      state.isLoading = false;
      state.verifyOtpResponse = action.payload;
    },
    newPasswordSuccessResponse(state, action) {
      state.isLoading = false;
      state.newPasswordResponse = action.payload;
    },
    changePasswordSuccessResponse(state, action) {
      state.isLoading = false;
      state.changePasswordResponse = action.payload;
    },
    getErrorMessage(state, action) {
      state.errorMessage = action.payload;
    },
  },
});
export default forgotPassword.reducer;
export const {
  startLoading,
  hasError,
  forgotPasswordSuccessResponse,
  verifyOtpSuccessResponse,
  changePasswordSuccessResponse,
  getErrorMessage,
  newPasswordSuccessResponse,
} = forgotPassword.actions;
