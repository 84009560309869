import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  error: null,
  EmployeeTarget: [],
  CompanyTarget: null,
  errorMessage: '',
};
const target = createSlice({
  name: 'target',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    stopLoading(state) {
      state.isLoading = false;
    },
    hasError(state) {
      state.isLoading = false;
      state.error = true;
    },
    getCompanyTarget(state, action) {
      state.CompanyTarget = action.payload;
      state.isLoading = false;
    },
    getErrMessage(state, action) {
      state.errorMessage = action.payload;
    },
  },
});

export default target.reducer;
export const { startLoading, stopLoading, hasError, getCompanyTarget, getEmployeeTarget, getErrMessage } =
  target.actions;
