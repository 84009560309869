import { lazy, Suspense } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import DashboardLayout from '../layouts/dashboard';
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import { PATH_AFTER_LOGIN } from '../configs/config';
import LoadingScreen from '../components/LoadingScreen';

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen isDashboard={false} />}>
      <Component {...props} />
    </Suspense>
  );

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
      ],
    },
    // Dashboard Routes
    {
      path: 'admin',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          children: [{ path: 'farmers', element: <FarmerList /> }],
        },
        {
          path: 'vendors',
          children: [
            { path: 'customers', element: <Vendors /> },
            { path: 'dealers', element: <DealerList /> },
          ],
        },
        {
          path: 'designation',
          children: [{ path: 'list', element: <Designation /> }],
        },

        {
          path: 'employee',
          children: [
            { path: 'active', element: <EmployeeList /> },
            { path: 'inactive', element: <EmployeeList /> },
            { path: 'deleted', element: <EmployeeList /> },
            {
              path: 'mda',
              children: [
                { path: 'active', element: <MDAEmployeeList /> },
                { path: 'inactive', element: <MDAEmployeeList /> },
                { path: 'deleted', element: <MDAEmployeeList /> },
              ],
            },
            { path: 'attendance', element: <EmployeeAttendance /> },
            { path: 'registered', element: <RegisteredEmployee /> },
            { path: 'rejected', element: <RejectedEmployee /> },
          ],
        },
        {
          path: 'inventory',
          children: [
            { path: 'category', element: <ProductCategory /> },
            { path: 'products', element: <ProductList /> },
          ],
        },
        {
          children: [
            {
              path: 'meeting',
              element: <MeetingTable />,
            },
          ],
        },
        { path: 'profile/personal-information', element: <Profile /> },
        {
          path: 'settings',
          children: [
            { path: 'notification', element: <NotificationSetting /> },
          ],
        },
        {
          children: [
            {
              path: 'UnauthorizedPage',
              element: <UnauthorizedPage />,
            },
          ],
        },
      ],
    },
    {
      path: '*',
      element: <Navigate to={PATH_AFTER_LOGIN} replace />,
    },
  ]);
}

// Authentication
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
// Dashboard
const ProductCategory = Loadable(lazy(() => import('../sections/@dashboard/tables/ProductCategoryTable')));
const ProductList = Loadable(lazy(() => import('../sections/@dashboard/tables/ProductListTable')));
const Vendors = Loadable(lazy(() => import('../sections/@dashboard/tables/VendorsList')));
const Designation = Loadable(lazy(() => import('../sections/@dashboard/tables/DesignationListTable')));
const EmployeeList = Loadable(lazy(() => import('../sections/@dashboard/tables/EmployeeList')));
const MDAEmployeeList = Loadable(lazy(() => import('../sections/@dashboard/tables/MDAEmployeeList')));
const EmployeeAttendance = Loadable(lazy(() => import('../sections/@dashboard/tables/EmployeeAttendanceReportTable')));
const RegisteredEmployee = Loadable(lazy(() => import('../sections/@dashboard/tables/RegisteredEmployeeListTable')));
const RejectedEmployee = Loadable(lazy(() => import('../sections/@dashboard/tables/RejectedEmployeeListTable')));
const FarmerList = Loadable(lazy(() => import('../sections/@dashboard/tables/FarmerList')));
const DealerList = Loadable(lazy(() => import('../sections/@dashboard/tables/DealerList')));
const MeetingTable = Loadable(lazy(() => import('../sections/@dashboard/tables/MeetingTable')));
const Profile = Loadable(lazy(() => import('../pages/dashboard/Profile')));
const NotificationSetting = Loadable(lazy(() => import('../sections/@dashboard/tables/NotificationSetting')));
const UnauthorizedPage = Loadable(lazy(() => import('../pages/UnauthorizedPage')));
