import { put } from 'redux-saga/effects';
import axios from '../../../utils/axios';
import { BASEURL } from '../../../BaseUrl/BaseUrl';
import {
  getSignupResponse,
  startLoading,
  getErrorMessage,
  hasError,
  getEmailVerifyResponse,
  setResponse,
  stopLoading,
} from '../../slices/signupProcess';

export function* signupSaga(state) {
  try {
    yield put(startLoading());
    const { sendData } = state;
    const response = yield axios.post(`${BASEURL}/companies`, sendData);
    yield put(getSignupResponse(response.data.meta.message));
    yield put(setResponse(response.status));
  } catch (error) {
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
  }
}

export function* signupEmailVerifySaga(state) {
  try {
    yield put(getErrorMessage(''));
    yield put(startLoading());
    const data = {
      token: state.token,
    };
    const response = yield axios.put(`${BASEURL}/auth/verifyEmail`, data);
    yield put(getEmailVerifyResponse(response.data.meta.message));
    yield put(setResponse(response.status));
    yield put(stopLoading());
  } catch (error) {
    yield put(stopLoading());
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
  }
}
