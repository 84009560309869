import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: null,
  error: false,
  leadTable: [],
  rejectedLeads: [],
  pendingLeads: [],
  leadbyid: null,
  leadOrder: [],
  leadDiscussion: [],
  leadReminder: [],
  fileDetail: null,
  rejectSuccess: false,
  rejectgmessage: '',
};
const leads = createSlice({
  name: 'leads',
  initialState,
  reducers: {
    startLoading(state, action) {
      state.isLoading = action.payload;
    },
    stopLoading(state) {
      state.isLoading = false;
    },
    hasError(state) {
      state.error = true;
    },
    getleadTable(state, action) {
      state.leadTable = action.payload;
      state.error = false;
    },
    getRejectedLeads(state, action) {
      state.rejectedLeads = action.payload;
      state.error = false;
    },
    getPendingLeads(state, action) {
      state.pendingLeads = action.payload;
      state.rejectSuccess = false;
    },
    getLeadbyId(state, action) {
      state.leadbyid = action.payload;
    },
    getLeaddiscussion(state, action) {
      state.leadDiscussion = action.payload;
      state.error = false;
    },
    getLeadReminder(state, action) {
      state.leadReminder = action.payload;
      state.error = false;
    },
    getLeadorder(state, action) {
      state.leadOrder = action.payload;
      state.error = false;
    },
    getBulkDetail(state, action) {
      const addID = action.payload.map((v) => ({
        id: `e99f09a7-dd88-49d5-b1c8-1daf80c2d7b${state.leadTable.length + 1}`,
        ...v,
      }));
      state.leadTable = [...state.leadTable, ...addID];
    },
    deleteLead(state, action) {
      state.leadTable = state.leadTable.filter((data) => data.id !== action.payload);
    },
    rejectLead(state, action) {
      state.rejectSuccess = true;
      state.rejectgmessage = action.payload;
    },
  },
});

export default leads.reducer;
export const {
  startLoading,
  stopLoading,
  hasError,
  getLeaddiscussion,
  getLeadReminder,
  getLeadorder,
  getleadTable,
  getLeadbyId,
  getRejectedLeads,
  getPendingLeads,
  getBulkDetail,
  deleteLead,
} = leads.actions;
