import { BrowserRouter } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import Router from './routes/index';
// theme
import ThemeProvider from './theme';
// components
import RtlLayout from './components/RtlLayout';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import NotistackProvider from './components/NotistackProvider';
import ThemeColorPresets from './components/ThemeColorPresets';

// redux
import { store, persistor } from './redux/store';
// contexts
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext';

export default function App() {
  return (
    <ReduxProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <CollapseDrawerProvider>
          <BrowserRouter>
            <ThemeProvider>
              <ThemeColorPresets>
                <RtlLayout>
                  <NotistackProvider>
                    <ProgressBarStyle />
                    <ScrollToTop />
                    <Router />
                  </NotistackProvider>
                </RtlLayout>
              </ThemeColorPresets>
            </ThemeProvider>
          </BrowserRouter>
        </CollapseDrawerProvider>
      </PersistGate>
    </ReduxProvider>
  );
}
