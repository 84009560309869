import { takeLatest } from 'redux-saga/effects';
import sagaActions from '../../actions/index';
import {
  vendorsListSaga,
  addVendorSaga,
  DeleteVendorRowSaga,
  editVendorSaga,
  getdesignation,
  getRoles,
  gerVendorDetail,
  vendorBulkUpload,
  vendorHierarchy,
  assignunassignevendore,
  getTerritory,
  vendorOrderSaga,
  ownStockDetilsSaga,
  getDistributorData,
  getCompetitorStockData,
  getpdfData,
  salesCustomerBulkUpload,
  dailySalesCollectionBulkUpload,
  vendorSalesSaga,
  vendorMonthWiseSalesSaga,
  vendorOwnStockSaga,
  vendorListSagaForView,
} from './vendorsListSaga';

export function* vendorsSaga() {
  yield takeLatest(sagaActions.GET_VENDORS, vendorsListSaga);
  yield takeLatest(sagaActions.GET_VENDOR_FOR_VIEW, vendorListSagaForView);
  yield takeLatest(sagaActions.ADD_VENDORS, addVendorSaga);
  yield takeLatest(sagaActions.DELETE_VENDORS, DeleteVendorRowSaga);
  yield takeLatest(sagaActions.EDIT_VENDORS, editVendorSaga);
  yield takeLatest(sagaActions.GET_DESIGNATION, getdesignation);
  yield takeLatest(sagaActions.GET_ROLES, getRoles);
  yield takeLatest(sagaActions.GET_VENDORDETAIL, gerVendorDetail);
  yield takeLatest(sagaActions.VENDOR_BULKUPLOAD, vendorBulkUpload);
  yield takeLatest(sagaActions.VENDOR_HIERARCHY, vendorHierarchy);
  yield takeLatest(sagaActions.ASSIGNED_VENDOR, assignunassignevendore);
  yield takeLatest(sagaActions.GET_TERRITORY, getTerritory);
  yield takeLatest(sagaActions.GET_GETPDFDATA, getpdfData);
  yield takeLatest(sagaActions.GET_VENDORORDER, vendorOrderSaga);
  yield takeLatest(sagaActions.GET_OWNSTOCKDETILS, ownStockDetilsSaga);
  yield takeLatest(sagaActions.GET_DISTRIBUTOR_DATA, getDistributorData);
  yield takeLatest(sagaActions.GET_COMPETITORSTOCK_DATA, getCompetitorStockData);
  yield takeLatest(sagaActions.CUSTOMER_SALES_UPLOAD, salesCustomerBulkUpload);
  yield takeLatest(sagaActions.DAILY_COLLECTION_UPLOAD_UPLOAD, dailySalesCollectionBulkUpload);
  yield takeLatest(sagaActions.VENDOR_SALES_DETILS, vendorSalesSaga);
  yield takeLatest(sagaActions.VENDOR_OWN_STOCK, vendorOwnStockSaga);
  yield takeLatest(sagaActions.VENDOR_MONTH_WISE_SALES, vendorMonthWiseSalesSaga);
}
