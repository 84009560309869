import { put, call } from 'redux-saga/effects';
import axios from '../../../utils/axios';
import { getPriceListData, startLoading, deletePriceList } from '../../slices/inventory';
import {
  getErrorMessage,
  hasError,
  setResponse,
  startFormLoading,
  getDeleteMessage,
  getSuccessMessage,
} from '../../slices/responseStatus';
import { BASEURL } from '../../../BaseUrl/BaseUrl';
import { isLogout } from '../../slices/login';

export function* getPriceList() {
  try {
    yield put(hasError(false));
    yield put(startLoading(true));
    const Token = window.localStorage.getItem('auth-token');
    const responses = yield axios.get(`${BASEURL}/price-list`, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });

    const priceData = responses?.data?.data?.priceList?.price;
    if (priceData === undefined) {
      yield put(getPriceListData([]));
    } else {
      yield put(getPriceListData(priceData));
    }
  } catch (error) {
    yield put(startLoading(false));
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
    if (error.error?.statusCode === 401) {
      yield put(isLogout(true));
    }
  }
}

export function* addnewPriceList(state) {
  try {
    yield put(hasError(false));
    yield put(startFormLoading());
    const { item } = state;
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.post(`${BASEURL}/price-list`, item, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    yield put(getSuccessMessage(response.data.meta.message));
    yield put(setResponse(response.status));
    yield call(getPriceList);
  } catch (error) {
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
  }
}

export function* deletePrice(state) {
  try {
    yield put(startLoading(true));
    yield put(hasError(false));
    const { id } = state;
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.delete(`${BASEURL}/price-list/${id}`, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    if (response.status === 200) {
      yield put(deletePriceList(id));
    }
    // yield call(getPriceList);
    yield put(startLoading(false));
    yield put(getDeleteMessage(response.data.meta.message));
  } catch (error) {
    yield put(startLoading(false));
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
  }
}

export function* updatePrice(state) {
  try {
    yield put(hasError(false));
    yield put(startFormLoading());
    const { item, index } = state;

    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.put(`${BASEURL}/price-list/${index}`, item, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    yield put(getSuccessMessage(response.data.meta.message));
    yield put(setResponse(response.status));
    yield call(getPriceList);
  } catch (error) {
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
  }
}
