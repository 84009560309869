import { all, spawn, call } from 'redux-saga/effects';
import { loginSaga } from './loginSaga';
import { leadsSaga } from './leadsSaga';
import { dashboardSaga } from './dashboardSaga';
import { employeeSaga } from './employeeSaga';
import { vendorsSaga } from './vendorsSaga';
import { ordersSaga } from './orderListSaga';
import { customerSaga } from './customerSaga';
import { uploadSaga } from './uploadFileSaga';
import { inventorySaga } from './inventorySaga';
import { regionMasterSaga } from './regionMasterSaga';
import { designationSaga } from './designationSaga.js';
import { forgotPasswordProcessSaga } from './forgotPassword';
import { meetingSaga } from './meetingListSaga';
// import { territorySaga } from './TerritorySaga';
import { customerListSaga } from './customerDetilsSaga';
import { notificationSettingSaga } from './notificationSaga';

// eslint-disable-next-line
export default function* rootSaga() {
  const sagas = [
    loginSaga,
    dashboardSaga,
    designationSaga,
    vendorsSaga,
    customerSaga,
    employeeSaga,
    uploadSaga,
    leadsSaga,
    ordersSaga,
    inventorySaga,
    regionMasterSaga,
    forgotPasswordProcessSaga,
    meetingSaga,
    customerListSaga,
    notificationSettingSaga,
  ];
  yield all(
    sagas.map((saga) =>
      // eslint-disable-next-line
      spawn(function* () {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (e) {
            console.debug(e);
          }
        }
      }),
    ),
  );
}
