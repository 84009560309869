import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  designationList: [],
  designationOfvendor: [],
  designationOfemployee: [],
  isLoading: false,
  error: false,
  designationByRole: [],
  firstSelectedData: [],
  secondSelectedData: [],
  designationRelationData: null,
  designationHierarchy: [],
  secondSelectedDesignationHierarchy: [],
  firstSelectedDesignationHierarchy: [],
};
const designation = createSlice({
  name: 'designation',
  initialState,
  reducers: {
    startLoading(state, action) {
      state.isLoading = action.payload;
    },
    getAllDesignation(state, action) {
      state.designationList = action.payload;
      state.isLoading = false;
    },
    addDesignation() {},
    updateDesignation() {},
    getDisgnationofVendor(state, action) {
      state.designationOfvendor = action.payload;
    },
    getDesignationofEmployee(state, action) {
      state.designationOfemployee = action.payload;
    },
    getDesignationByRoleId(state, action) {
      if (Array.isArray(action.payload)) {
        state.designationByRole = action.payload;
      } else {
        state.designationByRole = [...state.designationByRole, action.payload];
      }
    },
    setFirstSelected(state, action) {
      state.firstSelectedData = action.payload;
    },
    setSecondSelected(state, action) {
      state.secondSelectedData = action.payload;
    },
    getDesignationRelation(state, action) {
      state.designationRelationData = action.payload;
    },
    getDesignationHierarchy(state, action) {
      state.designationHierarchy = action.payload;
    },
    getFirstSelectedDesignationHierarchy(state, action) {
      state.firstSelectedDesignationHierarchy = action.payload;
    },
    getSecondSelectedDesignationHierarchy(state, action) {
      state.secondSelectedDesignationHierarchy = action.payload;
    },
    deleteDesignationSuccess(state, action) {
      state.designationByRole = state.designationByRole.filter((data) => data?.id !== action.payload);
    },
  },
});
export default designation.reducer;
export const {
  startLoading,
  getAllDesignation,
  getDesignationofEmployee,
  getDisgnationofVendor,
  addDesignation,
  updateDesignation,
  getDesignationByRoleId,
  setFirstSelected,
  setSecondSelected,
  getDesignationRelation,
  getDesignationHierarchy,
  deleteDesignationSuccess,
  getFirstSelectedDesignationHierarchy,
  getSecondSelectedDesignationHierarchy,
} = designation.actions;
