import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  error: false,
  errorMessage: '',
  response: '',
};
const territorySlice = createSlice({
  name: 'territories',
  initialState,
  reducers: {
    startTerritoryLoading(state, action) {
      state.isLoading = action.payload;
    },
  },
});

export default territorySlice.reducer;
export const { startTerritoryLoading } = territorySlice.actions;
