import {
  selectedRoles,
  modulesForOtherRoles,
  modulesForSpecifiedRoles,
  specifiedRoles,
} from '../roles/roleBasedModules';
import navConfig from '../layouts/dashboard/navbar/NavConfig';

const useManageSidebar = () => {
  const modifiedContent = navConfig[0]?.items?.map((item) => item);
  let collectSidebarContentAsPerRole;

  const getuser = JSON.parse(localStorage.getItem('admin') || '{}');
  if (selectedRoles.includes(getuser && getuser?.designation?.toUpperCase())) {
    collectSidebarContentAsPerRole = modifiedContent;
  } else if (specifiedRoles.includes(getuser && getuser?.designation?.toUpperCase())) {
    const filteredModules = modulesForSpecifiedRoles.reduce((acc, item) => {
      const foundModule = modifiedContent.find((menu) => item.name === menu.title);
      if (foundModule) {
        acc.push(foundModule);
      }
      return acc;
    }, []);
    collectSidebarContentAsPerRole = filteredModules;
  } else {
    const filteredModules = modulesForOtherRoles.reduce((acc, item) => {
      const foundModule = modifiedContent.find((menu) => item.name === menu.title);
      if (foundModule) {
        acc.push(foundModule);
      }
      return acc;
    }, []);
    collectSidebarContentAsPerRole = filteredModules;
  }
  return { collectSidebarContentAsPerRole };
};
export default useManageSidebar;
