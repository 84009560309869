import { put, call } from 'redux-saga/effects';
import axios from '../../../utils/axios';
import { BASEURL } from '../../../BaseUrl/BaseUrl';
import {
  getAllDesignation,
  startLoading,
  getDesignationByRoleId,
  setFirstSelected,
  setSecondSelected,
  getDesignationRelation,
  getDesignationHierarchy,
  deleteDesignationSuccess,
  getFirstSelectedDesignationHierarchy,
  getSecondSelectedDesignationHierarchy,
} from '../../slices/designation';
import {
  hasError,
  getErrorMessage,
  startFormLoading,
  getSuccessMessage,
  setResponse,
  stopFormLoading,
} from '../../slices/responseStatus';
import { isLogout } from '../../slices/login';

export function* getDesignation() {
  try {
    yield put(hasError(false));
    yield put(startLoading(true));
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.get(`${BASEURL}/designations`, {
      headers: {
        'x-auth-token': `${Token}`,
        'Cache-Control': 'no-cache',
      },
    });
    const designationData = response.data.data.Designations?.designation;
    yield put(getAllDesignation(designationData === undefined ? [] : designationData));
  } catch (error) {
    yield put(startLoading(false));
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
    if (error.error?.statusCode === 401) {
      yield put(isLogout(true));
    }
  }
}

export function* addDesignation(state) {
  try {
    yield put(getErrorMessage());
    yield put(hasError(false));
    yield put(startFormLoading());
    const { item } = state;
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.post(`${BASEURL}/designations`, item, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    yield put(getSuccessMessage(response.data.meta.message));
    yield put(setResponse(response.status));
    yield call(getDesignation, '');
  } catch (error) {
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
  }
}

export function* updateDesignationSaga(state) {
  try {
    yield put(hasError(false));
    yield put(startFormLoading());
    const { id, item } = state;
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.put(`${BASEURL}/designations/${id}`, item, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    if (response.status === 200) {
      yield put(getSuccessMessage(response.data.meta.message));
      yield put(setResponse(response.status));
    }
    yield call(getDesignation, '');

    // yield call(inventoryProductCategorySaga);
  } catch (error) {
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
  }
}

// note: this delete work is on hold
export function* deleteDesignationSaga(state) {
  try {
    yield put(hasError(false));
    yield put(startFormLoading());
    const { id } = state;
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.delete(`${BASEURL}/designations/${id}`, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    if (response.status === 200) {
      yield put(getSuccessMessage(response.data.meta.message));
      yield put(setResponse(response.status));
      yield put(deleteDesignationSuccess(id));
    }
  } catch (error) {
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
  }
}

export function* getDesignationByRole(state) {
  try {
    yield put(hasError(false));
    yield put(startFormLoading());
    const { id, selected } = state;
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.get(`${BASEURL}/designations/by-role/${id}`, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    const { Designations } = response.data.data;
    if (selected === 'first') {
      yield put(setFirstSelected(Designations === undefined ? [] : Designations));
    } else if (selected === 'second') {
      yield put(setSecondSelected(Designations === undefined ? [] : Designations));
    } else if (!selected) {
      yield put(getDesignationByRoleId(Designations === undefined ? [] : Designations));
    }
    yield put(stopFormLoading());
  } catch (error) {
    yield put(stopFormLoading());
    yield put(hasError(true));

    yield put(getErrorMessage(error.error.message));
  }
}

export function* getDesignationByRoleWithPriority(state) {
  try {
    yield put(hasError(false));
    yield put(startFormLoading());
    const { dept, selected } = state;
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.get(`${BASEURL}/designations/?dept=${dept}`, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    const designationData = response.data.data.Designations.designation;
    if (selected === 'first') {
      yield put(setFirstSelected(designationData === undefined ? [] : designationData));
    } else if (selected === 'second') {
      yield put(setSecondSelected(designationData === undefined ? [] : designationData));
    } else if (!selected) {
      yield put(getDesignationByRoleId(designationData === undefined ? [] : designationData));
    }
    yield put(stopFormLoading());
  } catch (error) {
    yield put(stopFormLoading());
    yield put(hasError(true));

    yield put(getErrorMessage(error.error.message));
  }
}

export function* relationBetweenDesignation(state) {
  try {
    yield put(hasError(false));
    yield put(startFormLoading());
    const { source, destination } = state;
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.get(
      `${BASEURL}/hierarchies/designation/relation?source=${source}&destination=${destination}`,
      {
        headers: {
          'x-auth-token': `${Token}`,
        },
      },
    );
    const { relation } = response.data.data;
    yield put(getDesignationRelation(relation === undefined ? [] : relation));
    yield put(stopFormLoading());
  } catch (error) {
    yield put(stopFormLoading());
    yield put(hasError(true));

    yield put(getErrorMessage(error.error.message));
  }
}

export function* updatetRelationOfDesignation(state) {
  try {
    yield put(hasError(false));
    yield put(startFormLoading());
    const { id, data } = state;

    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.put(`${BASEURL}/hierarchies/designation/relation/${id}`, data, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    if (response.status === 200) {
      yield put(getSuccessMessage(response.data.meta.message));
      yield put(setResponse(response.status));
    }

    yield put(stopFormLoading());
  } catch (error) {
    yield put(stopFormLoading());
    yield put(hasError(true));

    yield put(getErrorMessage(error.error.message));
  }
}

export function* getHierarchyOfDesignation(state) {
  try {
    yield put(hasError(false));
    yield put(startFormLoading());
    const { department, firstSelected, secondSelected } = state;

    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.get(`${BASEURL}/hierarchies/designation?dept=${department}`, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    if (response.status === 200) {
      const data = response.data.data.designations[0];

      if (firstSelected) {
        yield put(getFirstSelectedDesignationHierarchy(data === undefined ? [] : data));
      } else if (secondSelected) {
        yield put(getSecondSelectedDesignationHierarchy(data === undefined ? [] : data));
      } else {
        yield put(getDesignationHierarchy(data === undefined ? [] : data));
      }
    }
    yield put(stopFormLoading());
  } catch (error) {
    yield put(stopFormLoading());
    yield put(hasError(true));

    yield put(getErrorMessage(error.error.message));
  }
}

//
export function* updatetHierarchyOfDesignation(state) {
  try {
    yield put(hasError(false));
    yield put(startFormLoading());
    const { id, data } = state;
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.put(`${BASEURL}/hierarchies/designation/${id}`, data, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    if (response.status === 200) {
      yield put(getSuccessMessage(response.data.meta.message));
      yield put(setResponse(response.status));
    }
    yield put(stopFormLoading());
  } catch (error) {
    yield put(stopFormLoading());
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
  }
}
