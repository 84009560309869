import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  error: null,
  notificationSetting: [],
  timeForReminder: undefined,
  deleteNotificationStatus: undefined,
};
const notificationSetting = createSlice({
  name: 'notificationSetting',
  initialState,
  reducers: {
    startLoading(state, action) {
      state.isLoading = action.payload;
    },
    hasError(state, action) {
      state.error = action.payload;
    },
    addNewHolidays(state, action) {
      state.notificationSetting = [...state.notificationSetting, action.payload];
    },
    getHolidaysDetils(state, action) {
      state.isLoading = false;
      state.notificationSetting = action.payload;
      state.error = false;
    },
    deleteHolidays(state, action) {
      state.notificationSetting = state.notificationSetting.filter((data) => data.id !== action.payload);
    },
    getTimeForReminder(state, action) {
      state.timeForReminder = action.payload;
    },
    getDeleteNotificationStatus(state, action) {
      state.deleteNotificationStatus = action.payload;
    },
  },
});

export default notificationSetting.reducer;
export const {
  startLoading,
  hasError,
  getHolidaysDetils,
  addNewHolidays,
  deleteHolidays,
  getTimeForReminder,
  getDeleteNotificationStatus,
} = notificationSetting.actions;
