// ----------------------------------------------------------------------

export default function Timeline(theme) {
  return {
    MuiTimeline: {
      styleOverrides: {
        root: {
          padding: '0px 16px',
        },
      },
    },
    MuiTimelineDot: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },

    MuiTimelineConnector: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.divider,
        },
      },
    },
  };
}
