import { call, put } from 'redux-saga/effects';
import axios from '../../../utils/axios';
import {
  clearEmployeeList,
  deleteEmployee,
  filterEmployeeInStatusChange,
  getAssignedVendorForEmployee,
  getChildAssignEmployees,
  getChildDesignation,
  getChildUnassignEmployees,
  getCompetitorStockInEmployeeView,
  getEmployee,
  getEmployeeActivity,
  getEmployeeAllActivity,
  getEmployeeAssignmentData,
  getEmployeeBulkUploadResponse,
  getEmployeeById,
  getEmployeeDesignationList,
  getEmployeeDiscussion,
  getEmployeeFolloups,
  getEmployeeOrder,
  getEmployeeTarget,
  getEmployeeTraking,
  getMasterListOfEmployeesData,
  getOwnStockInEmployeeView,
  getResponse,
  getStatusChangeResponse,
  getUnassignedVendorForEmployee,
  getUpdatedMdaData,
  getVendorDesignationByEmployee,
  getVendoredesignationID,
  setEmployeeResponse,
  setFilterLoader,
  setNoData,
  startEmployeeDesignationLoading,
  startEmployeeLoading,
  startLoading,
  updateEmployee,
} from '../../slices/employee';
import { GettingRoleID } from '../../../components/GettingRoleID';
import {
  getDeleteMessage,
  getErrorMessage,
  getSuccessMessage,
  hasError,
  setResponse,
  startAssignmentLoader,
  startFormLoading,
  startUnAssignmentLoader,
  stopAssignmentLoader,
  stopFormLoading,
  stopUnAssignmentLoader,
} from '../../slices/responseStatus';
import { BASEURL } from '../../../BaseUrl/BaseUrl';
import { isLogout } from '../../slices/login';

export function* employeeListSaga(state) {
  try {
    const { regionId } = state;
    yield put(hasError(false));
    yield put(startLoading(true));
    yield put(startFormLoading());

    const Token = window.localStorage.getItem('auth-token');
    const id = GettingRoleID('employee');
    const response = yield axios.get(`${BASEURL}/users/?role=${id}&region=${regionId}`, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    const user = response?.data?.data?.users?.user;
    if (user === undefined) {
      yield put(getEmployee([]));
    } else {
      yield put(getEmployee(user));
    }
    yield put(stopFormLoading());
  } catch (error) {
    yield put(startLoading(false));
    yield put(stopFormLoading());

    yield put(getEmployee([]));
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
    if (error.error?.statusCode === 401) {
      yield put(isLogout(true));
    }
  }
}

export function* employeeListSagaForView(state) {
  try {
    const { regionId, designationId, rowsPerPage, page, designationIds, filterName } = state;
    yield put(hasError(false));
    yield put(startEmployeeLoading(true));
    yield put(startFormLoading());

    const Token = window.localStorage.getItem('auth-token');
    const model = localStorage.getItem('employeeModal');
    const id = GettingRoleID('employee');

    const addParams = `${designationId ? `&designation=${designationId}` : `&designation=${designationIds}`}`;
    const searchTextParams =
      filterName && filterName.trim() !== ''
        ? `&searchText=${filterName}&fieldname=first_name,last_name,contact_no`
        : '';
    const sortParams = `&sort=asc&column=first_name`;

    const getUrl = {
      active: `/users/?role=${id}&region=${regionId}&status=Active${addParams}&limit=${rowsPerPage}&page=${
        page + 1
      }${searchTextParams}${sortParams}`,
      inactive: `/users/?role=${id}&region=${regionId}&status=Inactive${addParams}&limit=${rowsPerPage}&page=${
        page + 1
      }${searchTextParams}${sortParams}`,
      deleted: `/users/?role=${id}&region=${regionId}&deleted=true${addParams}&limit=${rowsPerPage}&page=${
        page + 1
      }${searchTextParams}${sortParams}`,
    };
    const response = yield axios.get(getUrl[model], {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });

    yield put(startEmployeeLoading(false));
    const user = response?.data?.data?.users?.user;
    const count = response?.data?.data?.users?.totalRowsValue;
    if (user === undefined) {
      yield put(clearEmployeeList());
      yield put(getEmployee([]));
    } else {
      yield put(clearEmployeeList());
      yield put(getEmployee({ user, count }));
    }
    yield put(stopFormLoading());
  } catch (error) {
    yield put(startEmployeeLoading(false));
    yield put(stopFormLoading());
    yield put(getEmployee([]));
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
    if (error.error?.statusCode === 401) {
      yield put(isLogout(true));
    }
  }
}

export function* mdaEmployeeListSagaForView(state) {
  try {
    const { regionId, designationId, rowsPerPage, page, designationIds, filterName } = state;
    yield put(hasError(false));
    yield put(startEmployeeLoading(true));
    yield put(startFormLoading());

    const Token = window.localStorage.getItem('auth-token');
    const model = localStorage.getItem('mdaEmployeeModal');
    const id = GettingRoleID('employee');

    const addParams = `${designationId ? `&designation=${designationId}` : `&designation=${designationIds}`}`;
    const searchTextParams =
      filterName && filterName.trim() !== ''
        ? `&searchText=${filterName}&fieldname=first_name,last_name,contact_no`
        : '';
    const sortParams = `&sort=asc&column=first_name`;

    const getUrl = {
      active: `/users/?role=${id}&region=${regionId}&status=Active${addParams}&limit=${rowsPerPage}&page=${
        page + 1
      }${searchTextParams}${sortParams}`,
      inactive: `/users/?role=${id}&region=${regionId}&status=Inactive${addParams}&limit=${rowsPerPage}&page=${
        page + 1
      }${searchTextParams}${sortParams}`,
      deleted: `/users/?role=${id}&region=${regionId}&deleted=true${addParams}&limit=${rowsPerPage}&page=${
        page + 1
      }${searchTextParams}${sortParams}`,
    };
    const response = yield axios.get(getUrl[model], {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });

    yield put(startEmployeeLoading(false));
    const user = response?.data?.data?.users?.user;
    const count = response?.data?.data?.users?.totalRowsValue;
    if (user === undefined) {
      yield put(clearEmployeeList());
      yield put(getEmployee([]));
    } else {
      yield put(clearEmployeeList());
      yield put(getEmployee({ user, count }));
    }
    yield put(stopFormLoading());
  } catch (error) {
    yield put(startEmployeeLoading(false));
    yield put(stopFormLoading());
    yield put(getEmployee([]));
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
    if (error.error?.statusCode === 401) {
      yield put(isLogout(true));
    }
  }
}

export function* inactiveemployeeListSaga(state) {
  try {
    const { regionId } = state;
    yield put(hasError(false));
    yield put(startLoading(true));
    yield put(startFormLoading());

    const Token = window.localStorage.getItem('auth-token');
    const id = GettingRoleID('employee');
    const response = yield axios.get(`${BASEURL}/users/?role=${id}&region=${regionId}&status=inactive`, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });

    // eslint-disable-next-line prefer-destructuring
    const user = response?.data?.data?.users?.user;
    if (user === undefined) {
      yield put(getEmployee([]));
    } else {
      yield put(getEmployee(user));
    }
    yield put(stopFormLoading());
  } catch (error) {
    yield put(startLoading(false));
    yield put(stopFormLoading());

    yield put(getEmployee([]));
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
    if (error.error?.statusCode === 401) {
      yield put(isLogout(true));
    }
  }
}

export function* deleteEmployeeRowSaga(state) {
  try {
    yield put(startLoading(true));
    yield put(hasError(false));
    const { id } = state;
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.delete(`${BASEURL}/users/${id}`, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    if (response.status === 200) {
      yield put(deleteEmployee(id));
    }
    // yield call(employeeListSaga);
    yield put(startLoading(false));
    yield put(getDeleteMessage(response.data.meta.message));
  } catch (error) {
    yield put(startLoading(false));
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
  }
}

export function* getEmployeeByIdSaga(state) {
  try {
    yield put(startLoading(true));
    yield put(hasError(false));
    const { id } = state;
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.get(`${BASEURL}/users/${id}`, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });

    const user = response?.data?.data?.user[0];
    if (user === undefined) {
      yield put(getEmployeeById([]));
    } else {
      yield put(clearEmployeeList());
      yield put(getEmployeeById(user));
    }
    // yield call(employeeListSaga);
    yield put(startLoading(false));
  } catch (error) {
    yield put(startLoading(false));
    yield put(stopFormLoading());

    yield put(getEmployeeById([]));
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
    if (error.error?.statusCode === 401) {
      yield put(isLogout(true));
    }
  }
}

export function* addEmployeeListSaga(state) {
  try {
    yield put(hasError(false));
    yield put(startFormLoading());
    const { item } = state;
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.post(`${BASEURL}/users`, item, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    yield put(getSuccessMessage(response.data.meta.message));
    yield put(setResponse(response.status));
    yield call(employeeListSaga, '');
    yield put(setEmployeeResponse(response.status));
  } catch (error) {
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
  }
}

export function* editEmployeeListSaga(state) {
  try {
    yield put(hasError(false));
    yield put(startFormLoading());
    const { index, items, statusChange } = state;
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.put(`${BASEURL}/users/${index}`, items, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });

    if (!statusChange) {
      yield put(getSuccessMessage(response.data.meta.message));
      yield put(setResponse(response.status));
      yield put(updateEmployee(state?.items));
      yield put(setEmployeeResponse(response.status));
    }
    if (statusChange) {
      yield put(filterEmployeeInStatusChange(items.id));
    }

    yield put(stopFormLoading());
    // yield call(employeeListSaga, '');
  } catch (error) {
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
  }
}

export function* editMDAEmployeeListSaga(state) {
  try {
    yield put(hasError(false));
    yield put(startFormLoading());
    const { index, items, formData, statusChange } = state;
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.put(`${BASEURL}/users/mdaupdate/${index}`, formData, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    if (!statusChange) {
      yield put(getSuccessMessage(response.data.meta.message));
      yield put(setResponse(response.status));
      yield put(updateEmployee(items));
      yield put(getUpdatedMdaData(response?.data?.data[0]));
      yield put(setEmployeeResponse(response.status));
    }
    if (statusChange) {
      yield put(filterEmployeeInStatusChange(items.id));
    }
    yield put(stopFormLoading());
  } catch (error) {
    yield put(stopFormLoading());
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
  }
}

export function* getEmployeeDesignation() {
  try {
    yield put(hasError(false));
    yield put(startFormLoading());
    yield put(startEmployeeDesignationLoading(true));
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.get(`${BASEURL}/designations/?dept=employee`, {
      headers: {
        'x-auth-token': `${Token}`,
        'Cache-Control': 'no-cache',
      },
    });
    yield put(startEmployeeDesignationLoading(false));
    const designationsData = response.data.data.Designations.designation;
    yield put(getEmployeeDesignationList(designationsData === undefined ? [] : designationsData));
    yield put(stopFormLoading());
  } catch (error) {
    yield put(startEmployeeDesignationLoading(false));
    yield put(stopFormLoading());
    yield put(hasError(true));

    yield put(getErrorMessage(error.error.message));
  }
}

export function* employeeTargetSaga(state) {
  try {
    const { id, model, date, startDate, endDate, isEmployeeTarget, rowsPerPage, page } = state;
    const currentDay = new Date();
    const currentMonth = (currentDay.getMonth() + 1).toString().padStart(2, '0');
    const currnetYear = currentDay.getFullYear();
    // const getAdmin = JSON.parse(localStorage.getItem('admin') || '[]');
    yield put(hasError(false));
    yield put(startFormLoading());
    const Token = window.localStorage.getItem('auth-token');
    if (model === 'Details') {
      const getUserByID = yield axios.get(`${BASEURL}/users/${id}`, {
        headers: {
          'x-auth-token': `${Token}`,
        },
      });

      yield put(stopFormLoading());
      const currentuser = getUserByID.data.data?.user[0];
      yield put(getEmployeeById(currentuser));
    }
    if (isEmployeeTarget) {
      const response = yield axios.get(`${BASEURL}/targets/${id}?month=${currentMonth}&year=${currnetYear}`, {
        headers: {
          'x-auth-token': `${Token}`,
        },
      });
      const { TotalRevenue } = response.data.data;
      yield put(getEmployeeTarget(TotalRevenue));
      yield put(stopFormLoading());
    }

    if (model === 'Activity') {
      if (date === undefined) {
        const date = new Date();
        const currentDate = new Date(date).toISOString().substr(0, 10);

        const response = yield axios.get(`${BASEURL}/history/${id}?date=${currentDate}`, {
          headers: {
            'x-auth-token': `${Token}`,
          },
        });
        // const traking = yield axios.get(`${BASEURL}/userTracking/${id}`, {
        //   headers: {
        //     'x-auth-token': `${Token}`,
        //   },
        // });
        const { history } = response?.data?.data;
        const { activities } = response?.data?.data?.history;
        let tracking;
        if (response?.data?.data?.history?.live_tracking.length > 0) {
          tracking = response?.data?.data?.history?.live_tracking[0]?.tracking;
        } else {
          tracking = [];
        }
        yield put(getEmployeeAllActivity(history));
        yield put(getEmployeeActivity(activities === undefined ? [] : activities));

        yield put(getEmployeeTraking(tracking));
      } else {
        yield put(getEmployeeTraking([]));
        const response = yield axios.get(`${BASEURL}/history/${id}?date=${date}`, {
          headers: {
            'x-auth-token': `${Token}`,
          },
        });
        const { activities } = response?.data?.data?.history;
        // yield put(getEmployeeActivity(activities === undefined ? [] : activities));
        // const traking = yield axios.get(`${BASEURL}/userTracking/${id}?date=${date}`, {
        //   headers: {
        //     'x-auth-token': `${Token}`,
        //   },
        // });
        // yield put(getEmployeeActivity(UserActivities));
        const { history } = response?.data?.data;

        yield put(getEmployeeAllActivity(history));

        yield put(getEmployeeActivity(activities === undefined ? [] : activities));
        let tracking;
        if (response?.data?.data?.history?.live_tracking.length > 0) {
          tracking = response?.data?.data?.history?.live_tracking[0]?.tracking;
        } else {
          tracking = [];
        }

        yield put(getEmployeeTraking(tracking));
        // yield put(getEmployeeTraking(locations));
      }
    }
    if (model === 'Discussion') {
      const response = yield axios.get(`${BASEURL}/userActivities/user/${id}?event_type=discussion`, {
        headers: {
          'x-auth-token': `${Token}`,
        },
      });
      const { UserActivities } = response?.data?.data;

      yield put(stopFormLoading());
      yield put(getEmployeeDiscussion(UserActivities === undefined ? [] : UserActivities));
    }
    if (model === 'Orders') {
      const response = yield axios.get(
        `${BASEURL}/userActivities/user/${id}?event_type=order&startDate=${startDate}&endDate=${endDate}&limit=${rowsPerPage}&page=${
          page + 1
        }`,
        {
          headers: {
            'x-auth-token': `${Token}`,
          },
        }
      );
      const { UserActivities } = response?.data?.data;
      yield put(getEmployeeOrder(UserActivities === undefined ? [] : UserActivities));
    }
    if (model === 'Followups') {
      const response = yield axios.get(`${BASEURL}/userActivities/user/${id}?event_type=reminder`, {
        headers: {
          'x-auth-token': `${Token}`,
        },
      });
      const { UserActivities } = response?.data?.data;
      yield put(getEmployeeFolloups(UserActivities === undefined ? [] : UserActivities));
    }
    // if (model === 'Assignments') {
    //   yield axios.get(`${BASEURL}/userActivities/user/${getAdmin.id}?event_type=assignments`, {
    //     headers: {
    //       'x-auth-token': `${Token}`,
    //     },
    //   });
    //
    //   yield put(getEmployeeAssignemnt([]));
    // }

    if (model === 'Competitor Stock') {
      const response = yield axios.get(`${BASEURL}/userActivities/user/${id}?event_type=competitor_stock`, {
        headers: {
          'x-auth-token': `${Token}`,
        },
      });
      const { UserActivities } = response?.data?.data;
      yield put(getCompetitorStockInEmployeeView(UserActivities === undefined ? [] : UserActivities));
    }

    if (model === 'Own Stock') {
      const response = yield axios.get(`${BASEURL}/userActivities/user/${id}?event_type=own_stock`, {
        headers: {
          'x-auth-token': `${Token}`,
        },
      });
      const { UserActivities } = response?.data?.data;

      yield put(getOwnStockInEmployeeView(UserActivities === undefined ? [] : UserActivities));
    }

    yield put(stopFormLoading());
  } catch (error) {
    yield put(hasError(true));

    yield put(getErrorMessage(error.error.message));
  }
}

export function* employeeBulkUpload(state) {
  try {
    yield put(startLoading(true));
    yield put(hasError(false));
    const { item } = state;
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.post(`${BASEURL}/users/employee/upload`, item, {
      headers: {
        'x-auth-token': `${Token}`,
        'content-type': 'multipart/form-data',
      },
    });
    if (response?.status === 200) {
      yield put(getEmployeeBulkUploadResponse(response.status));
    }
    yield put(startLoading(false));
  } catch (error) {
    yield put(startLoading(false));
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
  }
}

export function* childDesignationForEmployeeSaga(state) {
  try {
    const { currentDesignationID } = state;
    yield put(hasError(false));
    // yield put(startFormLoading());
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.get(`${BASEURL}/hierarchies/designation/${currentDesignationID}?dept=employee`, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    const childDesignationID = response.data.data.designations[0].id;

    yield put(getChildDesignation(childDesignationID));
  } catch (error) {
    yield put(hasError(true));

    yield put(getErrorMessage(error.error.message));
  }
}

export function* unassignEmployeeSaga(state) {
  try {
    yield put(startUnAssignmentLoader());
    const { childDesignation, id } = state;
    const Token = window.localStorage.getItem('auth-token');
    if (childDesignation === '87a58fc1-8a56-4998-9d13-2ec6ec67a25b') {
      const unassignEmployeeResponse = yield axios.get(
        // child designaitonid
        `${BASEURL}/userManagement/unassigned/?child_designation_id=${childDesignation}&role=employee&user_id=${id}&child=true`,
        {
          headers: {
            'x-auth-token': `${Token}`,
          },
        }
      );
      yield put(getChildUnassignEmployees(unassignEmployeeResponse.data.data.Unassigned_users));
    } else if (childDesignation) {
      const unassignEmployeeResponse = yield axios.get(
        // child designaitonid
        `${BASEURL}/userManagement/unassigned/employee/?role=employee&user_id=${id}&child=true`,
        {
          headers: {
            'x-auth-token': `${Token}`,
          },
        }
      );
      yield put(stopUnAssignmentLoader());
      yield put(getChildUnassignEmployees(unassignEmployeeResponse.data.data.Unassigned_users));
    }
  } catch (error) {
    yield put(stopUnAssignmentLoader());
    yield put(hasError(true));

    yield put(getErrorMessage(error.error.message));
  }
}

export function* assignEmployeeSaga(state) {
  try {
    yield put(startAssignmentLoader());
    yield put(setNoData(false));

    const { id } = state;
    const Token = window.localStorage.getItem('auth-token');
    // user id
    const response = yield axios.get(`${BASEURL}/userManagement/${id}`, {
      headers: {
        'x-auth-token': `${Token}`,
        'Cache-Control': 'no-cache',
      },
    });
    const data = response.data.data.Employees;
    yield put(getChildAssignEmployees(data));
    if (data.length === 0) {
      yield put(setNoData(true));
    }
    yield put(stopAssignmentLoader());
  } catch (error) {
    yield put(stopAssignmentLoader());
    yield put(hasError(true));

    yield put(getErrorMessage(error.error.message));
  }
}

export function* updateAssignUnassignSaga(state) {
  try {
    yield put(hasError(false));
    yield put(startFormLoading());
    const { item, id } = state;
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.put(`${BASEURL}/userManagement/${id}`, item, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    yield put(getResponse(response.status));
    yield put(getEmployeeAssignmentData(item));
    yield put(stopFormLoading());
  } catch (error) {
    yield put(hasError(true));

    yield put(getErrorMessage(error.error.message));
  }
}

export function* employeeTovendorHierarchy(state) {
  try {
    const { DesignationID } = state;
    yield put(hasError(false));
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.get(`${BASEURL}/hierarchies/emp-vendor-designation/${DesignationID}`, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });

    // const { designations } = response.data.data;

    const designationsData = response?.data?.data?.designations;
    yield put(getVendoredesignationID(designationsData || null));
    // yield call(vendorsListSaga);
  } catch (error) {
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
  }
}

export function* unassignVendorToEmployee(state) {
  try {
    yield put(startUnAssignmentLoader());
    const { childDesignationID, currentUserId, currentEmployeeDesignation, character } = state;
    yield put(hasError(false));
    const Token = window.localStorage.getItem('auth-token');

    if (currentUserId && childDesignationID) {
      const getUnassignuser = yield axios.get(
        `${BASEURL}/userManagement/unassigned/?child_designation_id=${childDesignationID}&user_id=${currentUserId}&role=employee&character=${character}`,
        {
          headers: {
            'x-auth-token': `${Token}`,
          },
        }
      );
      const unassigned = getUnassignuser.data.data.Unassigned_users;
      yield put(getUnassignedVendorForEmployee(unassigned === undefined ? [] : unassigned));
    } else if (currentEmployeeDesignation !== undefined) {
      const getUnassignuser = yield axios.get(
        `${BASEURL}/userManagement/unassigned/?child_designation_id=${childDesignationID}&role=employee`,
        {
          headers: {
            'x-auth-token': `${Token}`,
          },
        }
      );
      const unassigned = getUnassignuser.data.data.Unassigned_users;
      yield put(getUnassignedVendorForEmployee(unassigned === undefined ? [] : unassigned));
    }
    yield put(stopUnAssignmentLoader());
  } catch (error) {
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
  }
}

export function* assignVendorToEmployee(state) {
  const { currentUserId } = state;
  yield put(startAssignmentLoader());
  const Token = window.localStorage.getItem('auth-token');
  if (currentUserId) {
    const getAssignuser = yield axios.get(`${BASEURL}/userManagement/vendors/admin/distributor/${currentUserId}`, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    const assigned = getAssignuser.data.data.Vendors;
    yield put(getAssignedVendorForEmployee(assigned === undefined ? [] : assigned));
  }
  yield put(stopAssignmentLoader());
}

export function* getVendorsByEmployeeDesignation(state) {
  try {
    const { DesignationID } = state;
    yield put(hasError(false));
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.get(`${BASEURL}/hierarchies/vendors-under-employee/${DesignationID}`, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    const designationsData = response?.data?.data.vendors;
    yield put(getVendorDesignationByEmployee(designationsData));
    yield put(stopFormLoading());
  } catch (error) {
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
  }
}

export function* getMasterListOfEmployees(state) {
  try {
    yield put(hasError(false));
    yield put(setFilterLoader(true));
    const { searchUserText } = state;
    const Token = window.localStorage.getItem('auth-token');
    const loggedInUserDetails = window.localStorage.getItem('admin');
    const parsedData = JSON.parse(loggedInUserDetails);
    const searchTextParams = searchUserText && searchUserText.trim() !== '' ? `&searchText=${searchUserText}` : '';

    if (parsedData?.designation === 'admin' || parsedData?.designation === 'NH') {
      const response = yield axios.get(`${BASEURL}/users/getEmployee?sort=ASC${searchTextParams}`, {
        headers: {
          'x-auth-token': `${Token}`,
        },
      });
      yield put(setFilterLoader(false));
      yield put(getMasterListOfEmployeesData(response?.data?.data?.users));
    } else {
      const response = yield axios.get(
        `${BASEURL}/users/hierarchy/children/${parsedData?.id}?sort=ASC${searchTextParams}`,
        {
          headers: {
            'x-auth-token': `${Token}`,
          },
        }
      );
      yield put(setFilterLoader(false));
      yield put(getMasterListOfEmployeesData(response?.data?.data?.user));
    }
  } catch (error) {
    yield put(setFilterLoader(false));
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
  }
}

export function* updateUserStatusSaga(state) {
  try {
    const { id, status } = state;
    yield put(hasError(false));
    yield put(startLoading(true));
    yield put(startFormLoading());

    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.put(`${BASEURL}/users/status/${id}?status=${status}`, null, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    yield put(startLoading(false));
    yield put(stopFormLoading());
    yield put(getStatusChangeResponse(response?.status));
  } catch (error) {
    yield put(startLoading(false));
    yield put(stopFormLoading());
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
  }
}

export function* assignVendorSaga(state) {
  try {
    yield put(hasError(false));
    yield put(startFormLoading());
    const { index, items, statusChange } = state;
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.put(`${BASEURL}/users/assingvendor/${index}`, items, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });

    if (!statusChange) {
      yield put(getSuccessMessage(response.data.meta.message));
      yield put(setResponse(response.status));
      yield put(updateEmployee(state?.items));
      yield put(setEmployeeResponse(response.status));
    }
    if (statusChange) {
      yield put(filterEmployeeInStatusChange(items.id));
    }

    yield put(stopFormLoading());
    // yield call(employeeListSaga, '');
  } catch (error) {
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
  }
}
