import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  error: null,
  meeting: [],
  meetingReportStatus: '',
  meetingCount: undefined,
};
const meeting = createSlice({
  name: 'meeting',
  initialState,
  reducers: {
    startLoading(state, action) {
      state.isLoading = action.payload;
    },
    hasError(state, action) {
      state.error = action.payload;
    },
    getMeeting(state, action) {
      state.isLoading = false;
      state.meeting = action.payload;
      state.error = false;
    },
    getMeetingReportStatus(state, action) {
      state.isLoading = false;
      state.meetingReportStatus = action.payload;
      state.error = false;
    },
    getMeetingCount(state, action) {
      state.meetingCount = action.payload;
    },
  },
});

export default meeting.reducer;
export const { startLoading, hasError, getMeeting, getMeetingReportStatus,getMeetingCount } = meeting.actions;
