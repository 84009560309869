function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/admin';

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify'),
  signup: path(ROOTS_AUTH, '/signup'),
  signupOtpVerify: path(ROOTS_AUTH, 'signup/otp-verify'),
  thankyou: path(ROOTS_AUTH, '/thankyou'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/dashboard'),
    vendor: path(ROOTS_DASHBOARD, '/vendor'),
    customer: path(ROOTS_DASHBOARD, '/customer'),
    banking: path(ROOTS_DASHBOARD, '/leads'),
    booking: path(ROOTS_DASHBOARD, '/orders'),
    invoice: path(ROOTS_DASHBOARD, '/invoice'),
    profile: path(ROOTS_DASHBOARD, '/profile'),
    changepassword: path(ROOTS_DASHBOARD, '/changepassword'),
  },
  inventory: {
    root: path(ROOTS_DASHBOARD, '/inventory'),
    category: path(ROOTS_DASHBOARD, '/inventory/category'),
    products: path(ROOTS_DASHBOARD, '/inventory/products'),
    stock: path(ROOTS_DASHBOARD, '/inventory/stock'),
    productimport: path(ROOTS_DASHBOARD, '/inventory/products/import'),
    stockimport: path(ROOTS_DASHBOARD, '/inventory/stock/import'),
    price: path(ROOTS_DASHBOARD, '/inventory/price'),
  },
  designation: {
    root: path(ROOTS_DASHBOARD, '/designation'),
    // viewemployeedetail: path(ROOTS_DASHBOARD, '/employee/employeelist/detail'),
    // employeetarget: path(ROOTS_DASHBOARD, '/employee/employeetarget'),
    designationlist: path(ROOTS_DASHBOARD, '/designation/list'),
    hierarchy: path(ROOTS_DASHBOARD, '/designation/hierarchy'),
    designation_relation: path(ROOTS_DASHBOARD, '/designation/designation-relation'),
  },
  employee: {
    root: path(ROOTS_DASHBOARD, '/employee'),
    viewemployeedetail: path(ROOTS_DASHBOARD, '/employee/employeelist/detail'),
    employeetarget: path(ROOTS_DASHBOARD, '/employee/employeetarget'),
    employeelist: path(ROOTS_DASHBOARD, '/employee/active'),
    attendance: path(ROOTS_DASHBOARD, '/employee/attendance'),
    registered: path(ROOTS_DASHBOARD, '/employee/registered'),
    rejected: path(ROOTS_DASHBOARD, '/employee/rejected'),
    mda: path(ROOTS_DASHBOARD, '/employee/mda/active'),
  },
  invoice: {
    root: path(ROOTS_DASHBOARD, '/invoice'),
  },
  settings: {
    root: path(ROOTS_DASHBOARD, '/settings'),
    notification: path(ROOTS_DASHBOARD, '/settings/notification'),
    territoryUpload: path(ROOTS_DASHBOARD, '/settings/territoryUpload'),
    // employeerole: path(ROOTS_DASHBOARD, '/settings/employeerole'),
    // supplychain: path(ROOTS_DASHBOARD, '/settings/supplychain'),
    // orderqnty: path(ROOTS_DASHBOARD, '/settings/orderqnty'),
  },
  lead: {
    root: path(ROOTS_DASHBOARD, '/leads'),
    Pending: path(ROOTS_DASHBOARD, '/leads/pending'),
    New: path(ROOTS_DASHBOARD, '/leads/new'),
    Reject: path(ROOTS_DASHBOARD, '/leads/rejected'),
    leadlist: path(ROOTS_DASHBOARD, '/leads/list'),
    inreviewleads: path(ROOTS_DASHBOARD, '/leads/inreviewleads'),
    rejectedleads: path(ROOTS_DASHBOARD, '/leads/rejected'),
    leadDetail: path(ROOTS_DASHBOARD, '/leads/leadDetail'),
    viewLeadDetail: path(ROOTS_DASHBOARD, '/leads/viewLeadDetail'),
  },
  order: {
    root: path(ROOTS_DASHBOARD, '/orders'),
    pending: path(ROOTS_DASHBOARD, '/orders/pending'),
    confirm: path(ROOTS_DASHBOARD, '/orders/confirm'),
    reject: path(ROOTS_DASHBOARD, '/orders/reject'),
    all: path(ROOTS_DASHBOARD, '/orders/all'),

    ViewOrderDetailsTable: path(ROOTS_DASHBOARD, '/orders/ViewOrderDetailsTable'),
  },
  regionMaster: {
    root: path(ROOTS_DASHBOARD, '/regionList'),
  },
  meeting: {
    root: path(ROOTS_DASHBOARD, '/meeting'),
  },
  vendors: {
    root: path(ROOTS_DASHBOARD, '/vendors'),
    customers: path(ROOTS_DASHBOARD, '/vendors/customers'),
    dealers: path(ROOTS_DASHBOARD, '/vendors/dealers'),
  },
  customerdetils: {
    root: path(ROOTS_DASHBOARD, '/customerdetils'),
  },
  farmerDetails: {
    root: path(ROOTS_DASHBOARD, '/farmers'),
  },
  dealerdetils: {
    root: path(ROOTS_DASHBOARD, '/dealerdetils'),
  },

  profile: {
    root: path(ROOTS_DASHBOARD, '/profile/personal-information'),
  },
  unitMaster: {
    root: path(ROOTS_DASHBOARD, '/unit'),
    unitMaster: path(ROOTS_DASHBOARD, '/unit/unitMaster'),
    hierarchy: path(ROOTS_DASHBOARD, '/unit/hierarchy'),
  },
  route: {
    root: path(ROOTS_DASHBOARD, '/route'),
  },
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
