import { takeLatest } from 'redux-saga/effects';
import sagaActions from '../../actions/index';
import { stockSaga } from './stockSaga';
import { distributerSaga } from './ditributerSaga';
import { dashboardTarget } from './dashboardtargetSaga';

export function* dashboardSaga() {
  yield takeLatest(sagaActions.GET_STOCKES, stockSaga);
  yield takeLatest(sagaActions.GET_DISTRIBUTERS, distributerSaga);
  yield takeLatest(sagaActions.Get_DASHBOARDTARGET, dashboardTarget);
  // yield takeLatest(sagaActions.GET_DASHBOARDEMPLOYEE_TARGET, employeeDashboardatarget);
}
