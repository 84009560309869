import { put, call } from 'redux-saga/effects';
import {
  stopLoading,
  startLoading,
  getleadTable,
  getRejectedLeads,
  getPendingLeads,
  getLeadbyId,
  getLeadorder,
  getLeaddiscussion,
  getLeadReminder,
  deleteLead,
} from '../../slices/leadslice';
import {
  setResponse,
  hasError,
  startFormLoading,
  stopFormLoading,
  getErrorMessage,
  getDeleteMessage,
  getSuccessMessage,
} from '../../slices/responseStatus';
import axios from '../../../utils/axios';
import { BASEURL } from '../../../BaseUrl/BaseUrl';
import { isLogout } from '../../slices/login';
import { GettingRoleID } from '../../../components/GettingRoleID';

export function* leadTableSaga() {
  try {
    yield put(hasError(false));
    yield put(startLoading(true));
    const Token = window.localStorage.getItem('auth-token');
    const model = localStorage.getItem('leadModal');
    if (model === 'new') {
      const newLeadsResponse = yield axios.get(`${BASEURL}/leads/status/?status=New`, {
        headers: {
          'x-auth-token': `${Token}`,
        },
      });
      const newLeads = newLeadsResponse.data.data.leads;
      yield put(getleadTable(newLeads === undefined ? [] : newLeads));
    }

    if (model === 'pending') {
      const pendingLeadResponse = yield axios.get(`${BASEURL}/leads/status/?status=Pending`, {
        headers: {
          'x-auth-token': `${Token}`,
        },
      });
      const pendingLeads = pendingLeadResponse.data.data.leads;
      yield put(getPendingLeads(pendingLeads === undefined ? [] : pendingLeads));
    }

    if (model === 'rejected') {
      const rejectedLeadResponse = yield axios.get(`${BASEURL}/leads/status/?status=Rejected`, {
        headers: {
          'x-auth-token': `${Token}`,
        },
      });
      const rejectedLeads = rejectedLeadResponse.data.data.leads;
      yield put(getRejectedLeads(rejectedLeads === undefined ? [] : rejectedLeads));
    }
    yield put(stopLoading());
  } catch (error) {
    yield put(hasError(true));
    yield put(stopLoading());
    yield put(getErrorMessage(error.error.message));
    if (error.error.message !== undefined) {
      const logoutErrorMessage = error.error.message === 'Invalid token' || 'No token provided';
      if (logoutErrorMessage) {
        yield put(isLogout(true));
      }
    }
  }
}
export function* addLeadSaga(state) {
  try {
    yield put(hasError(false));
    yield put(startFormLoading());
    const { item } = state;
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.post(`${BASEURL}/leads`, item, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    yield put(getSuccessMessage(response.data.meta.message));
    yield put(setResponse(response.status));
    yield call(leadTableSaga);
  } catch (error) {
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
  }
}
export function* editLeadSaga(state) {
  try {
    yield put(hasError(false));
    yield put(startFormLoading());

    const { item, id } = state;
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.put(`${BASEURL}/leads/${id}`, item, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    yield put(getSuccessMessage(response.data.meta.message));
    yield put(setResponse(response.status));
    yield call(leadTableSaga);
  } catch (error) {
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
  }
}
export function* deleteLeadSaga(state) {
  try {
    yield put(startLoading(true));
    yield put(hasError(false));
    const { id } = state;
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.delete(`${BASEURL}/leads/${id}`, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    if (response.status === 200) {
      yield put(deleteLead(id));
    }
    yield call(leadTableSaga);
    yield put(startLoading(false));
    yield put(getDeleteMessage(response.data.meta.message));
  } catch (error) {
    yield put(startLoading(false));
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
  }
}
export function* assignEmployeeSaga(state) {
  try {
    yield put(hasError(false));
    const { assignedEmployee, leadId, item } = state;

    const data = {
      employee_id: assignedEmployee.id,
      employee: assignedEmployee.full_name,
    };
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.post(`${BASEURL}/leads/transfer/${leadId}`, data, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });

    yield put(getleadTable(item));
    yield put(getDeleteMessage(response.data.meta.message));
  } catch (error) {
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
  }
}
export function* rejectLeadSaga(state) {
  try {
    yield put(hasError(false));
    const { id, reson, currentLead, filteredLead } = state;
    const { config } = currentLead;
    const data = {
      config: {
        Reason: reson,
        ...config,
      },
    };
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.put(`${BASEURL}/leads/reject/${id}`, data, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    // yield call(leadTableSaga);
    yield put(getPendingLeads(filteredLead));
    yield put(getDeleteMessage(response.data.meta.message));
  } catch (error) {
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
  }
}
export function* ApproveLeadSaga(state) {
  try {
    yield put(hasError(false));
    const roleId = GettingRoleID('vendor');
    const { id, filteredLead } = state;
    const item = {
      role_id: roleId,
    };
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.post(`${BASEURL}/leads/approve/${id}`, item, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    yield put(getPendingLeads(filteredLead));
    yield put(getDeleteMessage(response.data.meta.message));
    // yield call(leadTableSaga);
  } catch (error) {
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
  }
}
export function* LeadDetailSaga(state) {
  try {
    yield put(hasError(false));
    yield put(startFormLoading());
    const { layout, index } = state;
    const Token = window.localStorage.getItem('auth-token');
    if (layout === 'details') {
      const response = yield axios.get(`${BASEURL}/leads/${index}`, {
        headers: {
          'x-auth-token': `${Token}`,
        },
      });
      const { leads } = response.data.data;
      const data = leads[0];
      yield put(getLeadbyId(data));
    }
    if (layout === 'order') {
      const response = yield axios.get(`${BASEURL}/lead-activities/${index}/lead_order`, {
        headers: {
          'x-auth-token': `${Token}`,
        },
      });
      const orderOfLead = response.data.data.lead_activities;
      yield put(getLeadorder(orderOfLead === undefined ? [] : orderOfLead));
    }
    if (layout === 'discussion') {
      // 1f7f98ba-33e4-4d75-8cfd-44bbd1432d64 temporary id
      const response = yield axios.get(`${BASEURL}/lead-activities/${index}/lead_discussion`, {
        headers: {
          'x-auth-token': `${Token}`,
        },
      });
      const leadDiscussion = response.data.data.lead_activities;
      yield put(getLeaddiscussion(leadDiscussion === undefined ? [] : leadDiscussion));
    }
    if (layout === 'reminder') {
      // 1f7f98ba-33e4-4d75-8cfd-44bbd1432d64 temporary id

      const response = yield axios.get(`${BASEURL}/lead-activities/${index}/lead_reminder`, {
        headers: {
          'x-auth-token': `${Token}`,
        },
      });

      const leadReminder = response.data.data.lead_activities;

      yield put(getLeadReminder(leadReminder === undefined ? [] : leadReminder));
    }
    yield put(stopFormLoading());
  } catch (error) {
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
  }
}

export function* leadBulkUpload(state) {
  try {
    yield put(startLoading(true));
    yield put(hasError(false));
    const { item } = state;
    const Token = window.localStorage.getItem('auth-token');
    yield axios.post(`${BASEURL}/leads/upload`, item, {
      headers: {
        'x-auth-token': `${Token}`,
        'content-type': 'multipart/form-data',
      },
    });
    yield call(leadTableSaga);
  } catch (error) {
    yield put(stopLoading());
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
  }
}
