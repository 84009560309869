import { put } from 'redux-saga/effects';
import axios from '../../../utils/axios';
import { BASEURL } from '../../../BaseUrl/BaseUrl';

import {
  startLoading,
  hasError,
  forgotPasswordSuccessResponse,
  changePasswordSuccessResponse,
  getErrorMessage,
  verifyOtpSuccessResponse,
  newPasswordSuccessResponse,
} from '../../slices/forgotPasswordProcess';

export function* forgotPasswordSaga(state) {
  try {
    yield put(hasError(false));
    const { sendNumber } = state;
    yield put(startLoading());
    const response = yield axios.put(`${BASEURL}/auth/forgotPassword`, sendNumber);
    if (response.status === 200) {
      yield put(forgotPasswordSuccessResponse(response.status));
    }
  } catch (error) {
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
  }
}
export function* verifyOtpSaga(state) {
  try {
    const { verifyOtpData } = state;
    yield put(hasError(false));
    yield put(startLoading());
    const response = yield axios.put(`${BASEURL}/auth/otpVerify`, verifyOtpData);
    if (response.status === 200) {
      yield put(verifyOtpSuccessResponse(response.status));
      localStorage.setItem('tempToken', response.data.data.tempToken);
    }
  } catch (error) {
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
  }
}

export function* newPasswordSaga(state) {
  try {
    const { password, mobileNo } = state;
    const tempToken = window.localStorage.getItem('tempToken');
    const sendNewPassword = {
      password,
      mobileNo,
      tempToken,
    };

    yield put(hasError(false));
    yield put(startLoading());
    const response = yield axios.put(`${BASEURL}/auth/resetPassword`, sendNewPassword);
    if (response.status === 200) {
      yield put(newPasswordSuccessResponse(response.status));
      window.localStorage.removeItem('tempToken');
    }
  } catch (error) {
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
  }
}
export function* changePasswordSaga(state) {
  try {
    yield put(startLoading());
    const { changePasswordData } = state;
    const adminData = JSON.parse(localStorage.getItem('admin') || '{}');
    const token = window.localStorage.getItem('auth-token');
    const response = yield axios.put(`${BASEURL}/users/changePassword/${adminData.id}`, changePasswordData, {
      headers: {
        'x-auth-token': `${token}`,
      },
    });
    if (response.status === 200) {
      yield put(changePasswordSuccessResponse(response.status));
    }
  } catch (error) {
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message || error.message));
  }
}
