const sagaActions = {
  GET_PROFILE: 'GET_PROFILE',
  LOG_OUT: 'LOG_OUT',
  SIGNIN_SAGA: 'SIGNIN_SAGA',
  SIGNUP_SAGA: 'SIGNUP_SAGA',
  GET_SIGNUP_EMAIL_VERIFICATION: 'GET_SIGNUP_EMAIL_VERIFICATION',
  UPDATE_PROFILE: 'UPDATE_PROFILE',
  GET_STOCKES: 'GET_STOCKES',
  GET_DISTRIBUTERS: 'GET_DISTRIBUTERS',
  Get_DASHBOARDTARGET: 'Get_DASHBOARDTARGET',
  GET_DASHBOARDEMPLOYEE_TARGET: 'GET_DASHBOARDEMPLOYEE_TARGET',
  GET_CUSTOMERS: 'GET_CUSTOMERS',
  GET_VENDORS: 'GET_VENDORS',
  GET_VENDOR_FOR_VIEW: 'GET_VENDOR_FOR_VIEW',
  GET_EMPLOYEE: 'GET_EMPLOYEE',
  GET_EMPLOYEE_BY_ID: 'GET_EMPLOYEE_BY_ID',
  GET_INACTIVE_EMPLOYEE: 'GET_INACTIVE_EMPLOYEE',
  GET_MEETING_DETAILS: 'GET_MEETING_DETAILS',
  DOWNLOAD_MEETING_DETAILS: 'DOWNLOAD_MEETING_DETAILS',
  GET_EMPLOYEE_VIEW: 'GET_EMPLOYEE_VIEW',
  GET_MDA_EMPLOYEE_VIEW: 'GET_MDA_EMPLOYEE_VIEW',
  GET_EMPLOYEE_TARGET: 'GET_EMPLOYEE_TARGET',
  GET_EMPLOYEE_DETAILS: 'GET_EMPLOYEE_DETAILS',
  GET_INVOICE: 'GET_INVOICE',
  GET_PRODUCT: 'GET_PRODUCT',
  GET_PRICELIST: 'GET_PRICELIST',
  GET_PRODUCT_CATEGORY: 'GET_PRODUCT_CATEGORY',
  GET_PRODUCT_STOCK: 'GET_PRODUCT_STOCK',
  GET_PACKAGELIST: 'GET_PACKAGELIST',
  GET_REGIONLIST: 'GET_REGIONLIST',
  GET_UNITLIST: 'GET_UNITLIST',
  GET_UNIT_HIERARCHY: 'GET_UNIT_HIERARCHY',
  UPDATE_UNIT_HIERARCHY: 'UPDATE_UNIT_HIERARCHY',
  GET_ORDERLIST: 'GET_ORDERLIST',
  GET_DESIGNATION: 'GET_DESIGNATION',
  GET_EMPLOYEE_DESIGNATION: 'GET_EMPLOYEE_DESIGNATION',
  GET_ROLES: 'GET_ROLES',
  GET_LEADDETAIL: 'GET_LEADDETAIL',
  GET_ORDERDETAILS: 'GET_ORDERDETAILS',
  GET_EMPLOYEE_ATTENDANCE: 'GET_EMPLOYEE_ATTENDANCE',
  GET_CUSTOMER_DETILS: 'GET_CUSTOMER_DETILS',
  DOWNLOAD_CUSTOMER_DETILS: 'DOWNLOAD_CUSTOMER_DETILS',
  CHANGE_ORDER_STATUS: 'CHANGE_ORDER_STATUS',
  GET_GETPDFDATA: 'GET_GETPDFDATA',
  GET_VENDORDETAIL: 'GET_VENDORDETAIL',
  GET_COMPETITORSTOCK_DATA: 'GET_COMPETITORSTOCK_DATA',
  GET_ASSIGN_EMPLOYEE: 'GET_ASSIGN_EMPLOYEE',
  GET_UNASSIGN: 'GET_UNASSIGN',
  UPDATE_ASSIGNMENT_OF_EMPLOYEE: 'UPDATE_ASSIGNMENT_OF_EMPLOYEE',
  GET_CHILD_DESIGNATION: 'GET_CHILD_DESIGNATION',
  GET_PRODUCT_BY_ID: 'GET_PRODUCT_BY_ID',
  GET_OWNSTOCKDETAILS: 'GET_OWNSTOCKDETAILS',
  // GET_EMPLOYEE_TARGET_BY_ID:'GET_EMPLOYEE_TARGET_BY_ID',
  UPDATE_EMPLOYEE_TARGET: 'UPDATE_EMPLOYEE_TARGET',
  GET_CONFIGURE_TARGET: 'GET_CONFIGURE_TARGET',
  GET_STOCK_BY_ID: 'GET_STOCK_BY_ID',
  UPDATE_STOCKLIST: 'UPDATE_STOCKLIST',
  GET_FEATURE_DATA: 'GET_FEATURE_DATA',
  SET_FEATURE_DATA: 'SET_FEATURE_DATA',
  GET_ALL_DISCOUNT_DATA: 'GET_ALL_DISCOUNT_DATA',
  GET_ALL_ROUTE: 'GET_ALL_ROUTE',
  GET_INVOICE_PREFIX: 'GET_INVOICE_PREFIX',
  GET_INVOICE_LIST: 'GET_INVOICE_LIST',
  GET_ALL_INVOICE_LIST: 'GET_ALL_INVOICE_LIST',
  GET_SIDEBAR_CONTENT: 'GET_SIDEBAR_CONTENT',
  GET_VENDORS_BY_EMPLOYEE_DESIGNATION: 'GET_VENDORS_BY_EMPLOYEE_DESIGNATION',
  GET_DESIGNATION_BYROLE_WITH_PRIORITY: 'GET_DESIGNATION_BYROLE_WITH_PRIORITY',
  GET_DELETED_EMPLOYEE_BY_DESIGNATION: 'GET_DELETED_EMPLOYEE_BY_DESIGNATION',
  GET_USERS_BY_STATUS: 'GET_USERS_BY_STATUS',
  ASSIGN_DELETED_USER: 'ASSIGN_DELETED_USER',
  REJECT_USER: 'REJECT_USER',
  GET_HOLIDAYS: 'GET_HOLIDAYS',
  GET_NEGATIVE_STOCK: 'GET_NEGATIVE_STOCK',
  GET_TERRITORY: 'GET_TERRITORY',
  GET_VENDORORDER: 'GET_VENDORORDER',
  GET_OWNSTOCKDETILS: 'GET_OWNSTOCKDETILS',
  GET_HOLIDAYS_DETAILS: 'GET_HOLIDAYS_DETAILS',
  UPLOAD_TERRITORY: 'UPLOAD_TERRITORY',
  GET_RANDOM_IMAGE: 'GET_RANDOM_IMAGE',
  EDIT_RANDOM_IMAGE: 'EDIT_RANDOM_IMAGE',
  GET_TAX_SETTING: 'GET_TAX_SETTING',
  UPDATE_TAX_SETTING: 'UPDATE_TAX_SETTING',
  VENDOR_SALES_DETILS: 'VENDOR_SALES_DETILS',
  VENDOR_MONTH_WISE_SALES: 'VENDOR_MONTH_WISE_SALES',
  VENDOR_OWN_STOCK: 'VENDOR_OWN_STOCK',
  GET_MASTER_LIST_OF_EMPLOYEE: 'GET_MASTER_LIST_OF_EMPLOYEE',

  ADD_CUSTOMERS: 'ADD_CUSTOMERS',
  ADD_VENDORS: 'ADD_VENDORS',
  ADD_EMPLOYEE_LIST: 'ADD_EMPLOYEE_LIST',
  ADD_EMPLOYEE_TARGET: 'ADD_EMPLOYEE_TARGET',
  ADD_INVOICE: 'ADD_INVOICE',
  ADD_LEAD: 'ADD_LEAD',
  ADD_PACKAGELIST: 'ADD_PACKAGELIST',
  EDIT_PACKAGE: 'EDIT_PACKAGE',
  STATUSCHANGE_OF_PACKAGE: 'STATUSCHANGE_OF_PACKAGE',
  ADD_CATEGORY: 'ADD_CATEGORY',
  ADD_PRODUCT: 'ADD_PRODUCT',
  ADD_PRODUCT_STOCK: 'ADD_PRODUCT_STOCK',
  ADD_MULTIPLE_STOCK: 'ADD_MULTIPLE_STOCK',
  ADD_PRICE: 'ADD_PRICE',
  ADD_REGION: 'ADD_REGION',
  ASSIGN_EMPLOYEE: 'ASSIGN_EMPLOYEE',
  ADD_UNIT: 'ADD_UNIT',
  APPPROVE_LEAD: 'APPPROVE_LEAD',
  REJECT_LEAD: 'REJECT_LEAD',
  ASSIGNED_EMPLOYEES_BY_PRODUCTID: 'ASSIGNED_EMPLOYEES_BY_PRODUCTID',
  UNASSIGNED_EMPLOYEES_BY_PRODUCTID: 'UNASSIGNED_EMPLOYEES_BY_PRODUCTID',
  ADD_NEW_DISCOUNT: 'ADD_NEW_DISCOUNT',
  ADD_ROUTE: 'ADD_ROUTE',
  ADD_INVOICE_PREFIX: 'ADD_INVOICE_PREFIX',
  ADD_NEW_INVOICE: 'ADD_NEW_INVOICE',
  ADD_HOLIDAYS_DETAILS: 'ADD_HOLIDAYS_DETAILS',
  GET_DISTRIBUTOR_DATA: 'GET_DISTRIBUTOR_DATA',
  CUSTOMER_SALES_UPLOAD: 'CUSTOMER_SALES_UPLOAD',
  DAILY_COLLECTION_UPLOAD_UPLOAD: 'DAILY_COLLECTION_UPLOAD_UPLOAD',

  DELETE_CUSTOMERS: 'DELETE_CUSTOMERS',
  DELETE_VENDORS: 'DELETE_VENDORS',
  DELETE_EMPLOYEE_LIST: 'DELETE_EMPLOYEE_LIST',
  DELETE_EMPLOYEE_TARGET: 'DELETE_EMPLOYEE_TARGET',
  DELETE_INVOICE: 'DELETE_INVOICE',
  DELETE_LEAD: 'DELETE_LEAD',
  DELETE_PACKAGE: 'DELETE_PACKAGE',
  DELETE_REGION: 'DELETE_REGION',
  DELETE_CATEGORY: 'DELETE_CATEGORY',
  DELETE_PRODUCT: 'DELETE_PRODUCT',
  DELETE_PRICE: 'DELETE_PRICE',
  DELETE_UNIT: 'DELETE_UNIT',
  DELETE_HOLIDAYS_DETAILS: 'DELETE_HOLIDAYS_DETAILS',
  DELETE_DISCOUNT: 'DELETE_DISCOUNT',
  DELETE_ROUTE: 'DELETE_ROUTE',
  DELETE_STOCK: 'DELETE_STOCK',

  EDIT_CUSTOMERS: 'EDIT_CUSTOMERS',
  EDIT_VENDORS: 'EDIT_VENDORS',
  EDIT_EMPLOYEE_LIST: 'EDIT_EMPLOYEE_LIST',
  EDIT_MDA_EMPLOYEE_LIST: 'EDIT_MDA_EMPLOYEE_LIST',
  EDIT_EMPLOYEE_TARGET: 'EDIT_EMPLOYEE_TARGET',
  EDIT_INVOICE: 'EDIT_INVOICE',
  EDIT_LEAD: 'EDIT_LEAD',
  EDIT_CATEGORY: 'EDIT_CATEGORY',
  EDIT_PRODUCT: 'EDIT_PRODUCT',
  EDIT_PRICE: 'EDIT_PRICE',
  EDIT_REGION: 'EDIT_REGION',
  EDIT_UNIT: 'EDIT_UNIT',
  EDIT_DISCOUNT: 'EDIT_DISCOUNT',
  EDIT_ROUTE: 'EDIT_ROUTE',
  EDIT_NEGATIVE_STOCK: 'EDIT_NEGATIVE_STOCK',

  UPLOAD_FILE: 'UPLOAD_FILE',
  CHANGE_STATUS: 'CHANGE_STATUS',
  CHANGE_PRODUCT_STATUS: 'CHANGE_PRODUCT_STATUS',
  FOR_ORDER_CONFIRMATION: 'FOR_ORDER_CONFIRMATION',
  FOR_LEAD_ORDER_CONFIRMATION: 'FOR_LEAD_ORDER_CONFIRMATION',
  FOR_ORDER_REJECTION: 'FOR_ORDER_REJECTION',
  DOWNLOAD_ORDERREPORT: 'DOWNLOAD_ORDERREPORT',
  DOWNLOAD_ATTENDANCEREPORT: 'DOWNLOAD_ATTENDANCEREPORT',

  ORDERDETAIL_SAGA: 'ORDERDETAIL_SAGA',
  ORDERTABLE_SAGA: 'ORDERTABLE_SAGA',
  LEADTABLE_SAGA: 'LEADTABLE_SAGA',
  DISCUSSIONTABLE_SAGA: 'DISCUSSIONTABLE_SAGA',
  VIEWINVOICEDETAIL_SAGA: 'VIEWINVOICEDETAIL_SAGA',

  EMPLOYEE_BULKUPLOAD: 'EMPLOYEE_BULKUPLOAD',
  VENDOR_BULKUPLOAD: 'VENDOR_BULKUPLOAD',
  PRODUCT_BULKUPLOAD: 'PRODUCT_BULKUPLOAD',
  STOCK_BULKUPLOAD: 'STOCK_BULKUPLOAD',
  LEAD_BULKUPLOAD: 'LEAD_BULKUPLOAD',

  VENDOR_HIERARCHY: 'VENDOR_HIERARCHY',
  ASSIGNED_VENDOR: 'ASSIGNED_VENDOR',
  GET_DESIGNATIONLIST: 'GET_DESIGNATIONLIST',
  ADD_DESIGNATION: 'ADD_DESIGNATION',
  GET_DESIGNATION_BYROLE_ID: 'GET_DESIGNATION_BYROLE_ID',
  GET_RELATION_BETWEEN_DESIGNATION: 'GET_RELATION_BETWEEN_DESIGNATION',
  UPDATE_RELATION_BETWEEN_DESIGNATION: 'UPDATE_RELATION_BETWEEN_DESIGNATION',
  GET_DESIGNATION_HIERARCHY: 'GET_DESIGNATION_HIERARCHY',
  UPDATE_DESIGNATION_HIERARCHY: 'UPDATE_DESIGNATION_HIERARCHY',
  UPDATE_DESIGNATION: 'UPDATE_DESIGNATION',
  DELETE_DESIGNATION: 'DELETE_DESIGNATION',

  EMPLOYEE_VENDOR_DESIGNATION: 'EMPLOYEE_VENDOR_DESIGNATION',
  ASSIGN_VENDORTOEMPLOYEE: 'ASSIGN_VENDORTOEMPLOYEE',
  UNASSIGN_VENDORTOEMPLOYEE: 'UNASSIGN_VENDORTOEMPLOYEE',

  FORGOT_PASSWORD_ACTION: 'FORGOT_PASSWORD_ACTION',
  VERIFY_OTP_ACTION: 'VERIFY_OTP_ACTION',
  ADD_NEW_PASSWORD: 'ADD_NEW_PASSWORD',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  GET_NAVBAR_COUNT: 'GET_NAVBAR_COUNT',
  ADD_MULTIPLE_DESIGNATION: 'ADD_MULTIPLE_DESIGNATION',
  ASSIGN_EMPLOYEE_ON_ROUTE: 'ASSIGN_EMPLOYEE_ON_ROUTE',
  SEND_OTP_FOR_LOGIN: 'SEND_OTP_FOR_LOGIN',
  UPDATE_USER_STATUS: 'UPDATE_USER_STATUS',
  VENDOR_ASSINMENT: 'VENDOR_ASSINMENT',
};

export default sagaActions;
