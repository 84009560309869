import { takeLatest } from 'redux-saga/effects';
import sagaActions from '../../actions/index';
import {
  leadTableSaga,
  editLeadSaga,
  addLeadSaga,
  deleteLeadSaga,
  assignEmployeeSaga,
  LeadDetailSaga,
  rejectLeadSaga,
  ApproveLeadSaga,
  leadBulkUpload,
} from './leadTableSaga';

export function* leadsSaga() {
  yield takeLatest(sagaActions.LEADTABLE_SAGA, leadTableSaga);
  yield takeLatest(sagaActions.ADD_LEAD, addLeadSaga);
  yield takeLatest(sagaActions.EDIT_LEAD, editLeadSaga);
  yield takeLatest(sagaActions.DELETE_LEAD, deleteLeadSaga);
  yield takeLatest(sagaActions.ASSIGN_EMPLOYEE, assignEmployeeSaga);
  yield takeLatest(sagaActions.REJECT_LEAD, rejectLeadSaga);
  yield takeLatest(sagaActions.APPPROVE_LEAD, ApproveLeadSaga);
  yield takeLatest(sagaActions.GET_LEADDETAIL, LeadDetailSaga);
  yield takeLatest(sagaActions.LEAD_BULKUPLOAD, leadBulkUpload);
}
