import { put } from 'redux-saga/effects';
import axios from '../../../utils/axios';
import { startLoading, getMeeting, getMeetingReportStatus, getMeetingCount } from '../../slices/meeting';
import { hasError, startFormLoading, stopFormLoading, getErrorMessage } from '../../slices/responseStatus';
import { BASEURL } from '../../../BaseUrl/BaseUrl';
import { isLogout } from '../../slices/login';

export function* meetingListSaga(state) {
  try {
    const { startDate, endDate, rowsPerPage, page, filterName, selectedType, userId } = state;
    yield put(hasError(false));
    yield put(startLoading(true));
    yield put(startFormLoading());
    const Token = window.localStorage.getItem('auth-token');
    const searchTextParams = filterName && filterName.trim() !== '' ? `&searchText=${filterName}` : '';
    const selectedMeetingType = selectedType && selectedType !== 'all' ? `&type=${selectedType}` : '';
    const userID = userId ? `&userId=${userId}` : '';

    const response = yield axios.get(
      `${BASEURL}/meeting/list?startdate=${startDate}&enddate=${endDate}&limit=${rowsPerPage}&page=${
        page + 1
      }${searchTextParams}${selectedMeetingType}${userID}`,
      {
        headers: {
          'x-auth-token': Token,
        },
      }
    );
    const meetingData = response?.data?.data?.meeting?.data || [];
    const count = response?.data?.data?.meeting?.totalCount;

    yield put(getMeeting(meetingData));
    yield put(getMeetingCount(count));
    yield put(stopFormLoading());
  } catch (error) {
    yield put(startLoading(false));
    yield put(stopFormLoading());
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
    if (error.error?.statusCode === 401) {
      yield put(isLogout(true));
    }
  }
}
export function* downloadMeetingReportSaga(state) {
  try {
    const { startDate, endDate, filterName, selectedType, userId } = state;
    yield put(hasError(false));
    yield put(startLoading(true));
    yield put(startFormLoading());
    const Token = window.localStorage.getItem('auth-token');
    const searchTextParams = filterName && filterName.trim() !== '' ? `&searchText=${filterName}` : '';
    const selectedMeetingType = selectedType && selectedType !== 'all' ? `&type=${selectedType}` : '';
    const userID = userId ? `&userId=${userId}` : '';

    const response = yield axios.get(
      `${BASEURL}/meeting/list?startdate=${startDate}&enddate=${endDate}${searchTextParams}${selectedMeetingType}${userID}`,
      {
        headers: {
          'x-auth-token': Token,
        },
      }
    );
    const meetingData = response?.data?.data?.meeting?.data || [];
    yield put(getMeeting(meetingData));
    yield put(getMeetingReportStatus(response?.status));
    yield put(stopFormLoading());
  } catch (error) {
    yield put(startLoading(false));
    yield put(stopFormLoading());
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
    if (error.error?.statusCode === 401) {
      yield put(isLogout(true));
    }
  }
}
