import { put } from 'redux-saga/effects';
import axios from '../../../utils/axios';
import {
  startLoading,
  getRegionMaster,
  addNewRegionSuccess,
  deleteRegionSuccess,
  editRegionSuccess,
} from '../../slices/regionMaster';
import {
  setResponse,
  hasError,
  startFormLoading,
  getErrorMessage,
  getDeleteMessage,
  getSuccessMessage,
} from '../../slices/responseStatus';
import { BASEURL } from '../../../BaseUrl/BaseUrl';
import { isLogout } from '../../slices/login';

export function* regionListSaga() {
  try {
    yield put(startLoading(true));
    yield put(hasError(false));
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.get(`${BASEURL}/regions`, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    const regionData = response?.data?.data?.Regions?.region;
    if (regionData !== undefined) {
      localStorage.setItem('regionList', JSON.stringify(regionData));
      yield put(getRegionMaster(regionData));
    } else {
      yield put(getRegionMaster([]));
    }
  } catch (error) {
    yield put(startLoading(false));
    yield put(hasError(true));

    yield put(getErrorMessage(error.error.message));
    if (error.error?.statusCode === 401) {
      yield put(isLogout(true));
    }
  }
}

export function* addNewRegion(state) {
  try {
    yield put(hasError(false));
    yield put(startFormLoading());
    const { data } = state;
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.post(`${BASEURL}/regions`, data, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    if (response.status === 200) {
      let addNewRegionData;
      if (response.data.data.region.constructor === Array) {
        addNewRegionData = response.data.data.region;
      } else {
        addNewRegionData = [response.data.data.region];
      }

      const getRegion = JSON.parse(localStorage.getItem('regionList') || '[]');
      const dataArray = [...addNewRegionData, ...getRegion];
      localStorage.setItem('regionList', JSON.stringify(dataArray));
      yield put(addNewRegionSuccess(addNewRegionData));
      yield put(getSuccessMessage(response.data.meta.message));
      yield put(setResponse(response.status));
    }

    // yield call(regionListSaga);
  } catch (error) {
    yield put(hasError(true));

    yield put(getErrorMessage(error.error.message));
  }
}

export function* editRegionSaga(state) {
  try {
    yield put(hasError(false));
    yield put(startFormLoading());
    const { id } = state.id;
    const data = {
      region: state.editedData.region,
    };
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.put(`${BASEURL}/regions/${id}`, data, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    if (response.status === 200) {
      const getRegion = JSON.parse(localStorage.getItem('regionList') || '[]');
      const dataArray = getRegion.map((obj) => {
        if (obj?.id === id) {
          return {
            ...obj,
            region: data.region,
            id,
          };
        }
        return obj;
      });
      localStorage.setItem('regionList', JSON.stringify(dataArray));
      yield put(getSuccessMessage(response.data.meta.message));
      yield put(editRegionSuccess({ data, id }));
    }
    yield put(setResponse(response.status));
    // yield call(regionListSaga);
  } catch (error) {
    yield put(hasError(true));

    yield put(getErrorMessage(error.error.message));
  }
}

export function* deleteRegionRowSaga(state) {
  try {
    yield put(startLoading(true));
    yield put(hasError(false));
    const id = state.idForDelete;
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.delete(`${BASEURL}/regions/${id}`, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    // yield call(regionListSaga);
    if (response.status === 200) {
      yield put(deleteRegionSuccess(id));
      const getRegion = JSON.parse(localStorage.getItem('regionList') || '[]');
      const dataArray = getRegion.filter((item) => item.id !== id);
      localStorage.setItem('regionList', JSON.stringify(dataArray));
    }

    yield put(startLoading(false));
    yield put(getDeleteMessage(response.data.meta.message));
  } catch (error) {
    yield put(startLoading(false));
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
  }
}
