import { PATH_DASHBOARD } from '../routes/paths';
import meghmaniLogo from '../assets/meghmaniLogo/meghmani_logo.png';

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = PATH_DASHBOARD.employee.employeelist;

// LAYOUT
// ----------------------------------------------------------------------
export const Image = process.env.REACT_APP_IMAGE_PATH;
export const PSMLOGO = process.env.REACT_APP_LOGO_PATH;
export const PRODUCT_LOGO = meghmaniLogo;
export const HEADER = {
  MOBILE_HEIGHT: 64,
  MAIN_DESKTOP_HEIGHT: 88,
  DASHBOARD_DESKTOP_HEIGHT: 50,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 50,
};

export const NAVBAR = {
  BASE_WIDTH: 260,
  DASHBOARD_WIDTH: 240,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  //
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
  NAVBAR_ITEM: 20,
  NAVBAR_ITEM_HORIZONTAL: 20,
};

export const defaultSettings = {
  themeMode: 'light',
  themeDirection: 'ltr',
  themeColorPresets: process.env.THEME_PRESET_COLOR || 'purple',
  themeLayout: 'horizontal',
  themeStretch: false,
};
