import { takeLatest } from 'redux-saga/effects';
import sagaActions from '../../actions/index';
import { forgotPasswordSaga, changePasswordSaga, verifyOtpSaga, newPasswordSaga } from './forgotPasswordProcessSaga';

export function* forgotPasswordProcessSaga() {
  yield takeLatest(sagaActions.FORGOT_PASSWORD_ACTION, forgotPasswordSaga);
  yield takeLatest(sagaActions.VERIFY_OTP_ACTION, verifyOtpSaga);
  yield takeLatest(sagaActions.ADD_NEW_PASSWORD, newPasswordSaga);
  yield takeLatest(sagaActions.CHANGE_PASSWORD, changePasswordSaga);
}
