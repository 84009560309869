import { put } from 'redux-saga/effects';
import axios from '../../../utils/axios';
import { BASEURL } from '../../../BaseUrl/BaseUrl';
import { getCompanyTarget, startLoading, stopLoading } from '../../slices/target';
import { hasError, getErrorMessage } from '../../slices/responseStatus';
// import { getEmployeeTarget } from '../../slices/employee';
import { isLogout } from '../../slices/login';

export function* dashboardTarget(state) {
  try {
    const { regionId, region, month, year } = state;

    yield put(startLoading());
    const Token = window.localStorage.getItem('auth-token');
    const date = new Date();
    const currentMonth = month || (date.getMonth() + 1).toString().padStart(2, '0');
    const currnetYear = year || date.getFullYear();
    let url = `${BASEURL}/targets/company?month=${currentMonth}&year=${currnetYear}`;
    let sendRegionId;
    if (region !== 'All') {
      sendRegionId = `&region=${regionId}`;
      url = `${BASEURL}/targets/company?month=${currentMonth}&year=${currnetYear}${sendRegionId}`;
    }
    const response = yield axios.get(url, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });

    const { TotalRevenue } = response.data.data;

    if (TotalRevenue === undefined) {
      yield put(getCompanyTarget(''));
    } else {
      yield put(getCompanyTarget(TotalRevenue));
    }
  } catch (error) {
    yield put(hasError(true));
    yield put(stopLoading());
    yield put(getCompanyTarget(''));
    yield put(getErrorMessage(error.error.message));
    const logoutErrorMessage = error.error.message === 'Invalid token' || 'No token provided';
    if (logoutErrorMessage) {
      yield put(isLogout(true));
    }
  }
}

//  do not remove this code *****

// export function* employeeDashboardatarget(state) {
//   try {
//     const { id } = state;
//     // yield put(startLoading());
//     const Token = window.localStorage.getItem('auth-token');
//     if (id !== undefined) {
//       const employeeResponse = yield axios.get(`${BASEURL}/targets/${id}?month=3&year=2023`, {
//         headers: {
//           'x-auth-token': `${Token}`,
//         },
//       });
//       const { TotalRevenue } = employeeResponse.data.data;

//       yield put(getEmployeeTarget(TotalRevenue));
//     }
//     // yield put(stopLoading());
//   } catch (error) {
//     yield put(hasError());
//     yield put(getErrMessage(error.error.message));
//   }
// }
