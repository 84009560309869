import * as React from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useSelector } from '../redux/store';

const Alert = React.forwardRef((props, ref) => <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />);

Tostify.propTypes = {
  status: PropTypes.any,
  Message: PropTypes.string,
};

export default function Tostify({ status, Message }) {
  const [open, setOpen] = React.useState(false);

  const { error } = useSelector((state) => state.login);

  React.useEffect(() => {
    setOpen(status);
  }, [status, error]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          {Message || 'Something went wrong'}!
        </Alert>
      </Snackbar>
    </Stack>
  );
}
