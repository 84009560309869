import { useEffect } from 'react';
import { Icon } from '@iconify/react';
import { Autocomplete, InputAdornment, TextField } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { dispatch, useSelector } from '../../../redux/store';
import { getRegionvalue } from '../../../redux/slices/regionMaster';
import sagaActions from '../../../redux/actions';

export default function Region() {
  const { isLoading, region } = useSelector((state) => state.regionMaster);

  const theme = useTheme();
  const getRegion = JSON.parse(localStorage.getItem('regionList')) || [];
  useEffect(() => {
    if (getRegion.length === 0) {
      dispatch({ type: sagaActions.GET_REGIONLIST });
    }
    //  eslint-disable-next-line
  }, []);

  const regionList = getRegion.map((item) => item.region);
  const withAllRegion = [...regionList, 'All'];

  return (
    <>
      {isLoading ? (
        'Loading....'
      ) : (
        <Autocomplete
          disableClearable
          disablePortal
          id="combo-box-demo"
          size="small"
          defaultValue="All"
          inputValue={region}
          options={withAllRegion.sort((a, b) => {
            if (a === 'All') {
              return -1;
            }
            if (b === 'All') {
              return 1;
            }
            return 0;
          })}
          getOptionLabel={(option) => option}
          onInputChange={(event, value) => dispatch(getRegionvalue(value))}
          sx={{ width: 180,
            '& + .MuiAutocomplete-popper .MuiAutocomplete-option': {
              fontSize: '14px',
              padding: '3px 5px',
              marginTop: '3px',
              marginBottom: '3px',
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon
                      icon="subway:world-1"
                      color={`${theme.palette.primary.main}`}
                      style={{ width: '20px', height: '20px', paddingLeft: '8px' }}
                    />
                  </InputAdornment>
                ),
                style: {
                  height: "32px",
                  fontSize: '14px',
                  padding: 0
                },
              }}
            />
          )}
        />
      )}
    </>
  );
}
