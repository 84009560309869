// routes
import React from 'react';
import { PATH_DASHBOARD } from '../../../routes/paths';
import SvgIconStyle from '../../../components/SvgIconStyle';

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  dashboard: getIcon('ic_home_dashboard'),
  inventory: getIcon('ic_inventory'),
  vendors: getIcon('ic_vendors'),
  customers: getIcon('ic_customers'),
  leads: getIcon('ic_leads'),
  order: getIcon('ic_order'),
  employees: getIcon('ic_employees'),
  invoice: getIcon('ic_invoice'),
  setting: getIcon('ic_setting'),
  regionMaster: getIcon('region_master'),
  packageManagement: getIcon('package_management'),
  UnitMaster: getIcon('unit_master'),
  routeIcon: getIcon('ic_route'),
  discountIcon: getIcon('ic_discount'),
  customerIcon: getIcon('ic_customer'),
  farmerIcon: getIcon('ic_farmer'),
  dealerIcon: getIcon('ic_dealer'),
  meetingIcon: getIcon('ic_meeting'),
};

const prepareSidebarValueWithPath = [
  {
    items: [
      {
        title: 'Employees',
        path: PATH_DASHBOARD.employee.root,
        icon: ICONS.employees,
        children: [
          { title: 'Employees', path: PATH_DASHBOARD.employee.employeelist },
          { title: 'MDA', path: PATH_DASHBOARD.employee.mda },
          { title: 'Registered', path: PATH_DASHBOARD.employee.registered },
          { title: 'Rejected', path: PATH_DASHBOARD.employee.rejected },
          // { title: 'Employees target', path: PATH_DASHBOARD.employee.employeetarget },
          { title: 'Attendance report', path: PATH_DASHBOARD.employee.attendance },
        ],
        modulePath: 'employee',
      },
      {
        title: 'Customers',
        path: PATH_DASHBOARD.vendors.root,
        icon: ICONS.vendors,
        children: [
          {
            title: 'Distributors',
            path: PATH_DASHBOARD.vendors.customers,
          },
          {
            title: 'Dealers',
            path: PATH_DASHBOARD.vendors.dealers,
          },
        ],
        modulePath: 'vendors',
      },
      {
        title: 'Farmers',
        path: PATH_DASHBOARD.farmerDetails.root,
        icon: ICONS.farmerIcon,
        modulePath: 'farmers',
      },
      { title: 'Meeting', path: PATH_DASHBOARD.meeting.root, icon: ICONS.routeIcon, modulePath: 'meeting' },
      // { title: 'Order', path: PATH_DASHBOARD.order.root, icon: ICONS.order, modulePath: 'orders' },
      // { title: 'Vendors', path: PATH_DASHBOARD.general.vendor, icon: ICONS.vendors, modulePath: 'vendor' },
      // {
      //   title: 'Region Master',
      //   path: PATH_DASHBOARD.regionMaster.root,
      //   icon: ICONS.regionMaster,
      //   modulePath: 'regionList',
      // },
      {
        title: 'Inventory',
        path: PATH_DASHBOARD.inventory.root,
        icon: ICONS.inventory,
        children: [
          { title: 'Category', path: PATH_DASHBOARD.inventory.category },
          { title: 'Product', path: PATH_DASHBOARD.inventory.products },
          // { title: 'Stock Management', path: PATH_DASHBOARD.inventory.stock },
          // { title: 'Price List', path: PATH_DASHBOARD.inventory.price },
        ],
        modulePath: 'inventory',
      },
      {
        title: 'Designations',
        path: PATH_DASHBOARD.designation.designationlist,
        icon: ICONS.customers,
        modulePath: 'designation',
      },
      { title: 'Profile', path: PATH_DASHBOARD.profile.root, icon: ICONS.vendors, modulePath: 'profile' },
      {
        title: 'settings',
        path: PATH_DASHBOARD.settings.root,
        icon: ICONS.setting,
        children: [{ title: 'Notification', path: PATH_DASHBOARD.settings.notification }],
        modulePath: 'settings',
      },
    ],
  },
];

const navConfig = prepareSidebarValueWithPath;
export default navConfig;
