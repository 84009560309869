import { takeLatest, takeEvery } from 'redux-saga/effects';
import sagaActions from '../../actions/index';
import {
  getDesignation,
  addDesignation,
  getDesignationByRole,
  relationBetweenDesignation,
  getHierarchyOfDesignation,
  updatetHierarchyOfDesignation,
  updateDesignationSaga,
  updatetRelationOfDesignation,
  deleteDesignationSaga,
  getDesignationByRoleWithPriority,
} from './designationSaga';

export function* designationSaga() {
  yield takeLatest(sagaActions.GET_DESIGNATIONLIST, getDesignation);
  yield takeLatest(sagaActions.ADD_DESIGNATION, addDesignation);
  yield takeLatest(sagaActions.UPDATE_DESIGNATION, updateDesignationSaga);

  // note:do not delete commented code
  yield takeLatest(sagaActions.DELETE_DESIGNATION, deleteDesignationSaga);

  yield takeEvery(sagaActions.GET_DESIGNATION_BYROLE_ID, getDesignationByRole);
  yield takeEvery(sagaActions.GET_DESIGNATION_BYROLE_WITH_PRIORITY, getDesignationByRoleWithPriority);
  yield takeLatest(sagaActions.GET_RELATION_BETWEEN_DESIGNATION, relationBetweenDesignation);
  yield takeLatest(sagaActions.UPDATE_RELATION_BETWEEN_DESIGNATION, updatetRelationOfDesignation);

  yield takeLatest(sagaActions.GET_DESIGNATION_HIERARCHY, getHierarchyOfDesignation);
  yield takeLatest(sagaActions.UPDATE_DESIGNATION_HIERARCHY, updatetHierarchyOfDesignation);
}
