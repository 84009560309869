import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  response: '',
  formLoading: false,
  assignmentLoader: false,
  unAssignmentLoader: false,
  error: false,
  errorMessage: '',
  deleteState: false,
  deleteMessage: '',
  successMessage: '',
  bulkResponse: '',
};

const responsestatus = createSlice({
  name: 'responsestatus',
  initialState,
  reducers: {
    setResponse(state, action) {
      state.response = action.payload;
      state.formLoading = false;
    },
    setBulkResponse(state, action) {
      state.bulkResponse = action.payload;
      state.formLoading = false;
    },
    startFormLoading(state) {
      state.formLoading = true;
    },
    stopFormLoading(state) {
      state.formLoading = false;
    },
    startAssignmentLoader(state) {
      state.assignmentLoader = true;
    },
    stopAssignmentLoader(state) {
      state.assignmentLoader = false;
    },
    startUnAssignmentLoader(state) {
      state.unAssignmentLoader = true;
    },
    stopUnAssignmentLoader(state) {
      state.unAssignmentLoader = false;
    },
    hasError(state, action) {
      state.error = action.payload;
      if (state.error === true) {
        state.formLoading = false;
        state.assignmentLoader = false;
      }
    },
    getErrorMessage(state, action) {
      state.errorMessage = action.payload;
    },
    getSuccessMessage(state, action) {
      state.successMessage = action.payload;
    },
    isDelete(state, action) {
      state.deleteState = action.payload;
    },
    getDeleteMessage(state, action) {
      state.deleteState = true;
      state.deleteMessage = action.payload;
    },
  },
});

export default responsestatus.reducer;
export const {
  setResponse,
  hasError,
  startFormLoading,
  stopFormLoading,
  getErrorMessage,
  getDeleteMessage,
  isDelete,
  stopAssignmentLoader,
  startAssignmentLoader,
  getSuccessMessage,
  setBulkResponse,
  startUnAssignmentLoader,
  stopUnAssignmentLoader
} = responsestatus.actions;
