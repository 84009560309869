import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  error: false,
  pendingOrderList: [],
  confirmOrderList: [],
  rejectedOrderList: [],
  cancelOrderList: [],
  allOrderList: [],
  orderDetails: [],
  orderReport: [],
};
const orders = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state) {
      state.isLoading = false;
      state.error = true;
    },
    getPendingOrderList(state, action) {
      state.isLoading = false;
      state.pendingOrderList = action.payload;
      state.error = false;
    },
    getConfirmOrderList(state, action) {
      state.isLoading = false;
      state.confirmOrderList = action.payload;
      state.error = false;
    },
    getRejectedList(state, action) {
      state.isLoading = false;
      state.rejectedOrderList = action.payload;
      state.error = false;
    },
    getCancelledOrderList(state, action) {
      state.isLoading = false;
      state.cancelOrderList = action.payload;
      state.error = false;
    },
    getAllOrderList(state, action) {
      state.isLoading = false;
      state.allOrderList = action.payload;
      state.error = false;
    },
    editOrder(state, action) {
      state.isLoading = false;
      state.orderList = action.payload;
    },
    getOrderDetails(state, action) {
      state.isLoading = false;
      state.orderDetails = action.payload;
      state.error = false;
    },
    downLoadOrderReport(state, action) {
      state.isLoading = false;
      state.orderReport = action.payload;
      state.error = false;
    },
    stopLoading(state) {
      state.isLoading = false;
    },
  },
});

export default orders.reducer;
export const {
  startLoading,
  hasError,
  getCancelledOrderList,
  getConfirmOrderList,
  getPendingOrderList,
  getRejectedList,
  getAllOrderList,
  editOrder,
  getOrderDetails,
  downLoadOrderReport,
  stopLoading,
} = orders.actions;
