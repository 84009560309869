import { put, call } from 'redux-saga/effects';
import axios from '../../../utils/axios';
import {
  startLoading,
  hasError,
  getAllEmployeeTarget,
  getEmployeeTargetById,
  configureTargetData,
  updateEmployeeTargetReducer,
  deleteEmployeeTarget,
} from '../../slices/employee';
import { BASEURL } from '../../../BaseUrl/BaseUrl';
import {
  getDeleteMessage,
  getErrorMessage,
  setResponse,
  startFormLoading,
  stopFormLoading,
} from '../../slices/responseStatus';
import { isLogout } from '../../slices/login';

export function* employeeTargetListSaga() {
  try {
    yield put(hasError(false));
    yield put(startLoading(true));
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.get(`${BASEURL}/targets/list`, {
      headers: {
        'x-auth-token': `${Token}`,
        'Cache-Control': 'no-cache',
      },
    });
    const { targetData } = response.data.data.target_list;
    // const { users } = response.data.data;
    yield put(getAllEmployeeTarget(targetData === undefined ? [] : targetData));
  } catch (error) {
    yield put(startLoading(false));
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
    if (error.error?.statusCode === 401) {
      yield put(isLogout(true));
    }
  }
}
export function* addEmployeeTargetSaga(state) {
  try {
    yield put(hasError(false));
    yield put(startFormLoading());
    const { item } = state;
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.post(`${BASEURL}/targets`, item, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    yield put(setResponse(response.status));
    yield put(stopFormLoading());
    yield call(employeeTargetListSaga);
  } catch (error) {
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
  }
}

export function* employeeTargetById(state) {
  try {
    yield put(hasError(false));
    yield put(startFormLoading());
    const { id } = state;
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.get(`${BASEURL}/targets/${id}?month=06&year=2023`, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    const { TotalRevenue } = response.data.data;
    yield put(getEmployeeTargetById(TotalRevenue === undefined ? {} : TotalRevenue));
    yield put(stopFormLoading());
  } catch (error) {
    yield put(stopFormLoading());
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
  }
}
export function* updateEmployeeTarget(state) {
  try {
    yield put(hasError(false));
    yield put(startFormLoading());
    const { updatedData, targetId } = state;
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.put(`${BASEURL}/targets/${targetId}`, updatedData, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    yield put(setResponse(response.status));
    yield put(stopFormLoading());
    yield put(updateEmployeeTargetReducer({ targetId, updatedData }));
    // yield call(employeeTargetListSaga);
  } catch (error) {
    yield put(stopFormLoading());
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
  }
}

export function* getConfigureTargetData(state) {
  try {
    const { id, month, year } = state;
    yield put(hasError(false));
    yield put(startFormLoading());
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.get(`${BASEURL}/targets/all/${id}?month=${month}&year=${year}`, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    // eslint-disable-next-line camelcase
    const { target_list } = response.data.data;
    // eslint-disable-next-line camelcase
    yield put(configureTargetData(target_list === undefined ? [] : target_list));
    // yield put(getEmployeeDesignationList(Designations === undefined ? [] : Designations));
    yield put(stopFormLoading());
  } catch (error) {
    yield put(stopFormLoading());
    yield put(hasError(true));

    yield put(getErrorMessage(error.error.message));
  }
}

export function* deleteTargetSaga(state) {
  try {
    yield put(startLoading(true));
    yield put(hasError(false));
    const { id } = state;
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.delete(`${BASEURL}/targets/${id}`, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    if (response.status === 200) {
      yield put(deleteEmployeeTarget(id));
    }
    yield put(startLoading(false));
    yield put(getDeleteMessage(response.data.meta.message));
  } catch (error) {
    yield put(startLoading(false));
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
  }
}
