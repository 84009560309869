import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  error: false,
  errorMessage: '',
  signupResponse: '',
  emailVerifyResponse: undefined,
  responseInSignUp: '',
};

const signup = createSlice({
  name: 'signup',
  initialState,
  reducers: {
    setResponse(state, action) {
      state.responseInSignUp = action.payload;
    },
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getErrorMessage(state, action) {
      state.errorMessage = action.payload;
    },
    getSignupResponse(state, action) {
      state.isLoading = false;
      state.signupResponse = action.payload;
    },
    getEmailVerifyResponse(state, action) {
      state.isLoading = false;
      state.emailVerifyResponse = action.payload;
    },
    stopLoading(state) {
      state.isLoading = false;
    },
  },
});
export default signup.reducer;

export const {
  startLoading,
  hasError,
  getErrorMessage,
  getSignupResponse,
  getEmailVerifyResponse,
  setResponse,
  stopLoading,
} = signup.actions;
