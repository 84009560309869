import { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import { Box, Container } from '@mui/material';
import useSettings from '../../hooks/useSettings';
import useResponsive from '../../hooks/useResponsive';
import Tostify from '../../components/Tostify';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
import { HEADER, NAVBAR } from '../../configs/config';
import { useSelector, dispatch } from '../../redux/store';
import DashboardHeader from './header';
import NavbarVertical from './navbar/NavbarVertical';
import NavbarHorizontal from './navbar/NavbarHorizontal';
import sagaActions from '../../redux/actions';

const MainStyle = styled('main', {
  shouldForwardProp: (prop) => prop !== 'collapseClick',
})(({ collapseClick, theme }) => ({
  flexGrow: 1,
  paddingTop: HEADER.MOBILE_HEIGHT + 6,
  // paddingBottom: HEADER.MOBILE_HEIGHT + 24,
  [theme.breakpoints.up('lg')]: {
    background: '#fff',
    // paddingLeft: '4vh',
    // paddingRight: '4vh',
    // paddingTop: 100,
    // paddingBottom: 17,
    position: 'relative',
    right: 0,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
    ...(collapseClick && {
      marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
    }),
  },
}));

export default function DashboardLayout() {
  useEffect(() => {
    dispatch({ type: sagaActions.GET_SIDEBAR_CONTENT });
  }, []);
  const { collapseClick, isCollapse } = useCollapseDrawer();
  // const { TokenInValid } = useSelector((state) => state.login);
  const { themeLayout, themeStretch } = useSettings();
  const { IsLogoutMessage, IsLogoutFail } = useSelector((state) => state.login);
  const isDesktop = useResponsive('up', 'lg');

  const [open, setOpen] = useState(false);

  const verticalLayout = themeLayout === 'vertical';
  if (verticalLayout) {
    return (
      <>
        <DashboardHeader onOpenSidebar={() => setOpen(true)} verticalLayout={verticalLayout} />

        {isDesktop ? (
          <NavbarHorizontal />
        ) : (
          <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
        )}

        <Box
          component="main"
          sx={{
            px: { lg: 2 },
            pt: {
              xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
              lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 80}px`,
            },
            pb: {
              xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
              lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 24}px`,
            },
          }}
        >
          <Outlet />
        </Box>
      </>
    );
  }

  return (
    <Box
      sx={{
        display: { lg: 'flex' },
        minHeight: { lg: 1 },
      }}
    >
      <DashboardHeader isCollapse={isCollapse} onOpenSidebar={() => setOpen(true)} />

      <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />

      <MainStyle collapseClick={collapseClick}>
        <Tostify status={IsLogoutFail} Message={IsLogoutMessage} />
        <Container maxWidth={themeStretch ? false : '100%'}>
          <Outlet />
        </Container>
      </MainStyle>
    </Box>
  );
}
